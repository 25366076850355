import { useEffect, useState } from "react";
import { AbiItem } from "web3-utils";
import { ContractOptions } from "web3-eth-contract";
import Web3 from "web3";

import {
  getMasterChefAddress,
} from "utils/addressHelpers";
import { poolsConfig } from "config/constants";
import { PoolCategory } from "config/constants/types";
import masterChef from "config/abi/masterchef.json";
import sousChef from "config/abi/sousChef.json";
import sousChefBnb from "config/abi/sousChefBnb.json";
import { createWebInstance } from "utils/wallectConnectUtility";

const useContract = (
  abi: AbiItem,
  address: string,
  contractOptions?: ContractOptions
) => {
  const [web3, setWeb3] = useState<Web3>(null);
  const [contract, setContract] = useState(null);

  const setInstance = async () => {
    const webInstance = await createWebInstance();
    setWeb3(webInstance);
  };
  useEffect(() => {
    if (web3) {
      setContract(new web3.eth.Contract(abi, address, contractOptions));
    }
  }, [abi, address, contractOptions, web3]);
  useEffect(() => {
    setInstance();
  }, []);

  return contract;
};


export const useMasterchef = () => {
  const abi = masterChef as unknown as AbiItem;
  return useContract(abi, getMasterChefAddress());
};

export const useSousChef = (id) => {
  const config = poolsConfig.find((pool) => pool.sousId === id);
  const rawAbi =
    config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef;
  const abi = rawAbi as unknown as AbiItem;
  return useContract(
    abi,
    config.contractAddress[process.env.REACT_APP_CHAIN_ID]
  );
};

export default useContract;
