import { PancakeTheme } from "components/theme";
import { createGlobalStyle } from "styled-components";
// eslint-disable-next-line import/no-unresolved

declare module "styled-components" {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
a,h1{
  color:white !important;
}

  body {
    /* background-color: ${({ theme }) => theme.colors.background}; */
  background: radial-gradient( 164.47% 86.1% at 27.6% 25.03%, #12100e 0%, #2b4162 100% )
  
  ;


    img {
      height: auto;
      max-width: 100%;
    }
    
  }
  input{
    margin-right:20px;
  }
  button{
    background-color: ${({ theme }) => theme.colors.background} !important;
     border: 1px solid #27262c !important;
     color:#ffffff !important;
     cursor: pointer;
     }
  `;

export default GlobalStyle;
