import styled from "styled-components";
import Container from "./Container";

const Page = styled(Container)`
  /* min-height: calc(100vh - 64px); */
  min-height: auto;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: none;
  font-weight: 900;
  color: white !important;
  margin-top: 25px;

  .heading {
    @media (max-width: 600px) {
      margin-top: 25px;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export default Page;
