import React from "react";
import styled, { useTheme } from "styled-components";
import { Heading } from "@pancakeswap-libs/uikit";
import getThemeValue from "../util/getThemeValue";

import {
  ModalBody,
  ModalCloseButton,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from "../Modal";
import WalletCard from "./WalletCard";
import config from "./Config";
import Box from "../components/Box/Box";
import Grid from "../components/Box/Grid";

interface Props {
  login?: any;
  onDismiss?: () => void;
  displayCount?: number;
  handleClick?: any;
}

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

const ConnectModal: React.FC<Props> = ({
  login,
  onDismiss = () => null,
  displayCount = 5,
  handleClick
}) => {
  const showMore = false;
  const theme = useTheme();
  const sortedConfig = config;
  const displayListConfig = showMore
    ? sortedConfig
    : sortedConfig.slice(0, displayCount);

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader
        background={getThemeValue("colors.gradients.bubblegum")(theme)}
      >
        <ModalTitle>
          <Heading>Connect Wallet</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={["320px", null, "340px"]}>
        <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">
          <Grid gridTemplateColumns="">
            {displayListConfig.map((wallet) => (
              <Box key={wallet.title}>
                <WalletCard
                  walletConfig={wallet}
                  login={login}
                  onDismiss={onDismiss}
                  handleClick={handleClick}
                />
              </Box>
            ))}
          </Grid>
        </WalletWrapper>
        <Box p="24px" />
      </ModalBody>
    </ModalContainer>
  );
};

export default ConnectModal;
