/* eslint-disable*/
import React, { useState } from "react";
import { Heading } from "@pancakeswap-libs/uikit";
import Page from "components/layout/Page";
import useI18n from "hooks/useI18n";
import styled from "styled-components";
import PlebsCard from "./PlebsCard";

const Div = styled.div`
  text-align: center;
  /* margin-top: 25px !important; */
  @media (max-width: 600px) {
    margin-top: 25px !important;
  } ;
`;

export interface PlebProps {
  webInstance?: any;
  handleClick?: any;
}
const Plebs: React.FC<PlebProps> = (plebProps) => {
  const { webInstance, handleClick } = plebProps;

  return (
    <Page>
      <Div>
        <Heading mb="20px" style={{ textAlign: "center", fontSize: "30px" }}>
          Stake Your Plebeian NFTs
        </Heading>
        <Heading
          as="h1"
          color="secondary"
          mb="10px"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              paddingBottom: "20px",
            }}
          >
            The Plebeian Council is the principal assembly of the common people
            of vEmpire’s Metaverse adoption of ancient Rome.
          </p>
          <p
            style={{
              paddingBottom: "20px",
            }}
          >
            They will act as their holder’s identities, and provide unique
            benefits for their holders within vEmpire’s Roman ecosystem!
          </p>
          <p
            style={{
              paddingBottom: "20px",
              lineHeight: "20px",
            }}
          >
            To stake your Plebs, click on the NFT in the left hand box titled
            ‘Your Plebs’. Once highlighted, the ID numbers for the Plebs will
            appear in the box below. Once you’ve selected the NFTs to stake,
            press ‘Stake Plebs’.
          </p>
        </Heading>
        <PlebsCard handleClick={handleClick} />
      </Div>
    </Page>
  );
};

export default Plebs;
