/* eslint-disable react-hooks/exhaustive-deps */
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { useEffect, useState } from "react";
import { checkConnectedAndGetAddress } from "utils/farmHarvest";

declare const window: any;

const useUserAccount = () => {
  const { connect } = useWallet();
  const [account, setAccount] = useState(localStorage.getItem("account"));

  const getUserAccount = async () => {
    const _account = await checkConnectedAndGetAddress();
    if (!_account) {
      setAccount("");
      localStorage.setItem("account", "");
    } else {
      setAccount(_account);
      localStorage.setItem("account", _account);
    }
  };

  const login = async (connectId) => {
    getUserAccount();
    connect(connectId);
  };
  const reset = () => {
    localStorage.setItem("connectorIdv2", "");
    localStorage.setItem("account", "");
    setAccount("");
    localStorage.clear();

    return account;
  };

  useEffect(() => {
    if (localStorage.getItem("account")) {
      getUserAccount();
    }
    window.addEventListener("storage", getUserAccount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localStorage.getItem("connectorIdv2"),
    account,
    localStorage.getItem("account"),
  ]);

  return { account, login, reset };
};

export default useUserAccount;
