import React from "react";
import styled from "styled-components";
import { Button } from "@pancakeswap-libs/uikit";
import useI18n from "../../hooks/useI18n";
import Input, { InputProps } from "../Input";
import "../../views/Farms/components/css/Card.css";

const StyledSpacer = styled.div`
  width: ${(props) => props.theme.spacing[3]}px;
`;

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

interface TokenInputProps extends InputProps {
  max?: number | string;
  symbol?: string;
  onSelectMax?: () => void;
  depositFeeBP?: number;
  userAccountBalance?: number;
}

const TokenInput: React.FC<TokenInputProps> = ({
  onChange,
  onSelectMax,
  value,
}) => {
  const TranslateString = useI18n();
  return (
    <>
      <Input
        endAdornment={
          <StyledTokenAdornmentWrapper>
            <StyledSpacer />
            <div>
              <Button
                className="tokenInputButton"
                size="sm"
                onClick={onSelectMax}
              >
                {TranslateString(452, "Max")}
              </Button>
            </div>
          </StyledTokenAdornmentWrapper>
        }
        onChange={onChange}
        placeholder="0"
        value={value}
      />
    </>
  );
};

export default TokenInput;
