import React from "react";
import {
  Button,
  Text,
  Flex,
  LinkExternal,
  Modal,
} from "@pancakeswap-libs/uikit";
import CopyToClipboard from "./CopyToClipboard";
import { connectorLocalStorageKey } from "./Config";

interface Props {
  account: string;
  logout: () => void;
  onDismiss?: () => void;
}

const AccountModal: React.FC<Props> = ({
  account,
  logout,
  onDismiss = () => null,
}) => (
  <Modal title="Your wallet" onDismiss={onDismiss}>
    <Text
      fontSize="20px"
      bold
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "8px",
      }}
    >
      {account}
    </Text>
    <Flex mb="32px">
      <LinkExternal
        small
        href={`https://etherscan.com/address/${account}`}
        mr="16px"
      >
        View on Etherscan
      </LinkExternal>
      <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
    </Flex>
    <Flex justifyContent="center">
      <Button
        variant="secondary"
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          window?.dispatchEvent(new Event("storage"));
          onDismiss();
        }}
      >
        Logout
      </Button>
    </Flex>
  </Modal>
);

export default AccountModal;
