export const cake = "0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0";
export const masterChef = "0x11bdfc90ecB75e3F3eDa03021F403beE740DE862";
export const masterChefMana = "0x19bf5d317e7db7ba4829eb4d5c452b60ed0536e6"; // original
export const masterChefVemp = "0xA250F2302F1d80b1E8f639b5C2D60DA64dac569C";
// export const masterChefSand = "0xe41a2c643a918654650a123001FAAE1E9360BA3D"; // testnet address
export const masterChefSand = "0x7bdd633aab71ccfb348f3cf03392a63c093f311a"; // Mainnet address
// export const masterChefMana = "0x1B066bD9937d50a897CBC164a4D3F60258F9acf2"; // testnet address
export const wbnb = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const VempAddress = "0xcFEB09C3c5F0f78aD72166D55f9e6E9A60e96eEC"; // Mainnet address
// export const VempAddress = "0x5c9cd53478ae0d26946894da80c691289636190f";
// export const VempAddress = "0x791D61595bBa3C58442EbDF49d251E90ADF7f2dC"; // Testnet address

export const XVempAddress = "0x4a4e2d4859af854d2a3fc6df295843d21e70cc78";
export const governorAlphaAddress =
  "0x031b84F274ED5905A1D7ebfbF223700d8DC299e9";
export const lottery = "";
export const ddaoV2Eth = "0x87821c7fe32753ce37a293ed6e05bef789bb968b";
export const lotteryNFT = "";
export const mulltiCall = "0x1ee38d535d541c55c9dae27b12edf090c608e6fb";
export const busd = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
export const starLink = "0xaccf6B7109F59da4BE6c11eb1Cdf8Cdc9Cc9f722";
// export const starLink = "0x8b6547A1FB9730Df2EE783bb7C338a0533DE938B";
export const sand = "0x3845badAde8e6dFF049820680d1F14bD3903a5d0"; // Mainnet address
// export const sand = "0xbE0b64635D7c2c47c42e2a084e1A2f8038554Da6"; // Testnet address
export const axs = "0xdC16879d9Ef443151Aef5eC917ce64F083232A03";
// export const axs = "0x454Fe4ceb6b6D03205a703595A278d27ba384d69";
export const manaStake = "0x0f5d2fb29fb7d3cfee444a200298f468908cc942"; // Mainnet Address
// export const manaStake = "0xE659c7429b3FeC8224f95F89E3dad1b290b2dcF9"; // Testnet Address
export const cheffEth = "0x3a18b916b72fcab7ba7d2882eeb4cccea7f4ff42";

export const axsUnstake = "0x1e8f7af9fa74ed0e34b03208b27ba48d15b03be0";

export const ddaoV2Address = "0xC6773D7B7458f70AE6585C45bCA3D076e8537caF";
export const vempAddressV2 = "0x84c9767618f1895bbcbbd0780400e36989c67de8";
export const xVempAddressV2 = "0xa97ea487d483c82c5a8e3bbd70590bf21b77bd34";

export const bnbPoolAddress = "0xefeea8b08Dcd9CC5AA0D5aA801d8871810A0bc8C";
// export const sandPoolAddress = "0xF4c7969bc1A5e9225678f8DDD60983FA5C64Acf1"; // testnet address
export const sandPoolAddress = "0x1d26148B86C503aC48a446a280Ca558e820d43d5"; // Mainnet address
// export const manaPoolAddress = "0xf999BA2aAC655F3Be42222817891DEa189C68640"; // testnet address
export const manaPoolAddress = "0xE718834EF396e8e327B591734c7dF6f30687811C"; // Mainnet address
export const liquidityPoolAddress =
  "0x32565ea012D4FC31600a5924063eB52d0b179baE"; // Mainnet address
// export const liquidityPoolAddress =
//   "0xFbEAf52A62E6Da5de47e7Fdf873106688eC83868"; // testnet address

const mainNet = true;

// APE
export const apeStakingAddress = mainNet
  ? "0xE95Da387AA48cB7C87aF5DCFcA7b57840b165be1"
  : "0xcd18bc7348b4b3197504c08cf4915468d6e69889";
export const apeAddress = mainNet
  ? "0x4d224452801ACEd8B2F0aebE155379bb5D594381"
  : "0xd8845E5c204022550d95f90C9b36B4eAc82d005A";

// USDC testnet
export const usdcStakingAddress = mainNet
  ? "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
  : "0xaE30B2F21E8814AE33359b636cd7d295c6BF01AD";

export const busdStakingAddress = mainNet
  ? "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
  : "0xaE30B2F21E8814AE33359b636cd7d295c6BF01AC";

export const usdcAddress = mainNet
  ? "0x6E101F9C9A4d33F6f14c77aB71ff6Fc44FaA7536"
  : "0xAE1d2417F9686B3F01Ed50DCc8eDC3D835Dc08bD";

export const busdAddress = mainNet
  ? "0x310Cb26073646caA705113F214f90f96BAF75ce4"
  : "0xAE1d2417F9686B3F01Ed50DCc8eDC3D835Dc08bc";

// Pleb binance
export const plebAddress = mainNet
  ? "0xbC96f18700055C044Feb2902b5e39CDBa81CBf86"
  : "0xEeFE124982ae0aD8f19Bc404f91228545eedE534";
export const plebStakingAddress = mainNet
  ? "0x5759511bafc51275ee0Aa60D7f235C10eb1691Ac"
  : "0xF9E97862f8716d91ef613D2fc66fb4Fd017C3b8d";

export const adminUpgradabilityAddress =
  "0x32565ea012D4FC31600a5924063eB52d0b179baE";
// export const adminUpgradabilityAddress='0x0894A8CED819a6b0b306FbACE52D74d388Bf0284'
export const mockTokenAddress = "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec";
// export const mockTokenAddress='0x5c9cd53478ae0d26946894da80c691289636190f'
export const adminUpgradabilityStarLV2Address =
  "0x7aF128D95941a554AC54316d45c99881A3cAa0EB";
// export const adminUpgradabilityStarLV2Address='0x3ce6b3b2cb1502acaae6bc80b7d8f52f267b49dc'
export const adminUpgradabilityAxsV2Address =
  "0x19f653cf63159cf04505c0fe8da3e5d85f50da75";

// Soldier testnet
export const soldierAddress = mainNet
  ? "0x495f947276749ce646f68ac8c248420045cb7b5e"
  : "0xC7244b7a62810ef41B679492f4D11eA67c16Acc8";
export const soldierStakingAddress = mainNet
  ? "0xD8fb22BC9916B600eac0f726A02b3ED370f94322"
  : "0xce60125A3544BB3b363EA79F7733d50D91760844";