import React, { useMemo, useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import styled, { keyframes } from "styled-components";
import { Flex, Text } from "@pancakeswap-libs/uikit";
import { Vote } from "state/types";
import { provider } from "web3-core";
import useI18n from "hooks/useI18n";
import { getBalanceOfVempV2, vempPerXvemp } from "utils/VoteHarvest";
import { useAPRDetails } from "state/hooks";
import { QuoteToken } from "config/constants/types";
import CardHeading from "./CardHeading";
import CardActionsContainer from "./CardActionsContainer";

export interface FarmWithStakedValue extends Vote {
  apy?: BigNumber;
}

const RainbowLight = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledCardAccent = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 300% 300%;
  animation: ${RainbowLight} 2s linear infinite;
  border-radius: 16px;
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`;

const FCard = styled.div`
  border: 5px solid #8d8f8e;
  max-width: 280px;
  margin-top: 24px;
  align-self: baseline;
  background: black;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  text-align: center;
  min-height: 250px;
  max-height: 530px;
  background: linear-gradient(
    162.44deg,
    #000000 49.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
  border: 3px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
`;

interface FarmCardProps {
  farm: FarmWithStakedValue;
  removed: boolean;
  cakePrice?: BigNumber;
  bnbPrice?: BigNumber;
  ethereum?: provider;
  account?: string;
  webInstance: any;
  handleClick: any;
}

const VoteCard: React.FC<FarmCardProps> = ({
  farm,
  removed,
  cakePrice,
  bnbPrice,
  ethereum,
  account,
  webInstance,
  handleClick,
}) => {
  const TranslateString = useI18n();
  const aprDetails = useAPRDetails();
  const [vempV2Balance, setVempV2Balance] = useState(0);
  const [vempPerxVemp, setVempPerxVemp] = useState<any>(0);
  const farmImage = farm.isTokenOnly
    ? farm.tokenSymbol.toLowerCase()
    : `${farm.tokenSymbol.toLowerCase()}-${farm.quoteTokenSymbol.toLowerCase()}`;

  const totalValue: BigNumber = useMemo(() => {
    if (!farm.lpTotalInQuoteToken) {
      return null;
    }
    if (farm.quoteTokenSymbol === QuoteToken.BNB) {
      return bnbPrice.times(farm.lpTotalInQuoteToken);
    }
    if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
      return cakePrice.times(farm.lpTotalInQuoteToken);
    }
    return farm.lpTotalInQuoteToken;
  }, [bnbPrice, cakePrice, farm.lpTotalInQuoteToken, farm.quoteTokenSymbol]);

  const lpLabel = farm.lpSymbol;
  const earnLabel = farm?.depositFeeBP
    ?.toString()
    ?.match(/^-?\d+(?:\.\d{0,2})?/)[0];

  const { risk, vempV2Address } = farm;
  useEffect(() => {
    const getBalance = async () => {
      const v = await getBalanceOfVempV2(vempV2Address, webInstance);
      const vempPerxVepmm = await vempPerXvemp(
        farm.xVempV2Address,
        farm.ddaoV2Address,
        farm.vempV2Address,
        webInstance
      );
      if (v !== "NAN") {
        setVempV2Balance(v);
      }

      setVempPerxVemp(vempPerxVepmm);
    };
    if (farm.pid > 0) {
      getBalance();
    }
  }, [
    vempV2Address,
    farm.xVempV2Address,
    farm.ddaoV2Address,
    farm.pid,
    farm.vempV2Address,
    webInstance,
  ]);

  return (
    <FCard>
      {farm.tokenSymbol === "DDAO" && <StyledCardAccent />}
      <CardHeading
        lpLabel={lpLabel}
        multiplier={farm.multiplier}
        risk={risk}
        depositFee={farm.depositFeeBP}
        farmImage={farmImage}
        tokenSymbol={farm.tokenSymbol}
        pid={farm.pid}
      />
      {!removed &&
        (farm.pid > 0 ? (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="15px"
          >
            <Text fontSize="18px" style={{ fontWeight: 600 }}>
              {TranslateString(352, "APR")}:
            </Text>
            <Text
              fontSize="18px"
              style={{ display: "flex", alignItems: "center", fontWeight: 600 }}
            >
              {aprDetails?.VEMP_DISPLAY}
            </Text>
          </Flex>
        ) : (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="15px"
          >
            <Text fontSize="18px" style={{ fontWeight: 600 }}>
              {TranslateString(352, "APR")}:
            </Text>
            <Text
              fontSize="18px"
              bold
              style={{ display: "flex", alignItems: "center", fontWeight: 600 }}
            >
              {`${0}%`}
            </Text>
          </Flex>
        ))}
      <Flex justifyContent="space-between">
        <Text style={{ fontSize: "18px", fontWeight: 600 }}>
          {farm.pid > 0 ? "VEMP Available" : "Balance"}
        </Text>
        <Text style={{ margin: "auto 0", fontSize: "18px", fontWeight: 600 }}>
          {farm.pid > 0
            ? vempV2Balance?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0]
            : earnLabel}
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <Text style={{ fontSize: "18px", fontWeight: 600 }}>
          {farm.pid > 0 ? "Price Per xVEMP" : ""}
        </Text>

        <Text style={{ margin: "auto 0", fontSize: "18px", fontWeight: 600 }}>
          {farm.pid > 0 ? vempPerxVemp : ""}
        </Text>
      </Flex>
      <CardActionsContainer
        farm={farm}
        ethereum={ethereum}
        account={account}
        webInstance={webInstance}
        handleClick={handleClick}

      />
    </FCard>
  );
};

export default VoteCard;
