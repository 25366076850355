import React from "react";
import {
  Heading,
} from "@pancakeswap-libs/uikit";
import Page from "components/layout/Page";
import styled from "styled-components";
import { bnbNetworkId } from "config";
import VoteTabButtons from "./VoteTabButtons";
import Vote from "./Vote";

const Div = styled.div`
  text-align: center;
  /* margin-top: 25px !important; */
  @media (max-width: 600px) {
    margin-top: 25px !important;
  } ;
`;

export interface VoteProps {
  webInstance: any;
  handleClick: any;

}

const Votes: React.FC<VoteProps> = ({webInstance,handleClick}) => {

  return (
    <Page>
      <Div>
        <Heading mb="20px" style={{ textAlign: "center", fontSize: "30px" }}>
          DDAO - Community Governance
        </Heading>
        <Heading
          as="h1"
          color="secondary"
          mb="10px"
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          Stake your VEMP for xVEMP to qualify for the gifting of protocol
          profits, for voting, and for submitting community proposals.
        </Heading>
        {localStorage.getItem("chainId") !== bnbNetworkId ? (
          <VoteTabButtons />
        ) : (
          <div style={{ marginBottom: "50px" }} />
        )}

        <Vote webInstance={webInstance} handleClick={handleClick}/>
      </Div>
    </Page>
  );
};

export default Votes;
