import { Button } from "@pancakeswap-libs/uikit";
import UnlockButton from "components/UnlockButton";
import useI18n from "hooks/useI18n";
import React, { useCallback, useEffect, useState } from "react";
import {
  amountToWithdraw,
  approveVempV2,
  completeUnstake,
  getAllowancesVempV2,
  stakeV2,
} from "utils/VoteHarvest";
import {
  createWalletProvider,
  createWebInstance,
} from "utils/wallectConnectUtility";
import "../css/Vote.css";

function UnstackAll({ farm, onAllowanceOfV2, handleClick }) {
  const [approveAllowanceVempV2, setApproveAllowanceVempV2] =
    React.useState<number>(0);
  const [modalUnstakeDisable, setModalUnstakeDisable] = useState(false);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const isApprovedVempV2 = approveAllowanceVempV2 > 1;
  React.useEffect(() => {
    const onAllowance = async () => {
      const webInstance: any = await createWebInstance();
      const isAllowance: any = await getAllowancesVempV2(
        farm?.vempV2Address,
        farm?.ddaoV2Address,
        webInstance
      );
      setApproveAllowanceVempV2(isAllowance);
      onAllowanceOfV2();
    };
    onAllowance();
  }, [
    farm?.vempV2Address,
    farm?.ddaoV2Address,
    farm?.pid,
    requestedApproval,
    onAllowanceOfV2,
  ]);
  useEffect(() => {
    const chectForWithdrawAmount = async () => {
      const webInstance = await createWebInstance();

      const amount = await amountToWithdraw(webInstance);
      if (amount < 1) {
        setModalUnstakeDisable(true);
      }
    };
    if (farm.pid === 0) {
      chectForWithdrawAmount();
    }
  }, [farm.pid, modalUnstakeDisable]);

  const handleApproveVempV2 = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);
      await approveVempV2(farm.vempV2Address, farm.ddaoV2Address, instance);

      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, [farm.vempV2Address, farm.ddaoV2Address]);

  const handleStakeForModal = async () => {
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    await stakeV2(
      localStorage.getItem("unStakedAmount"),
      farm.ddaoV2Address,
      instance
    );
    localStorage.setItem("unStakedAmount", "0");
    window?.dispatchEvent(new Event("storage"));
  };

  const unStakeAllAmountAtOnce = async () => {
    const webInstance: any = await createWebInstance();
    const unStakedValue = await completeUnstake(webInstance);
    if (unStakedValue > 0) {
      localStorage.setItem("unStakedAmount", `${unStakedValue / 10 ** 18}`);
      setModalUnstakeDisable(true);
    }
  };

  const TranslateString = useI18n();
  const renderApprovalOrStakeForModal = () => {
    return isApprovedVempV2 ? (
      <Button
        m="0px"
        mt="8px"
        mb="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={!modalUnstakeDisable}
        onClick={() => handleStakeForModal()}
      >
        {TranslateString(999, "Stake")}
      </Button>
    ) : (
      <Button
        m="0px"
        mt="8px"
        mb="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={!modalUnstakeDisable}
        onClick={handleApproveVempV2}
      >
        {TranslateString(999, "Approve VEMP")}
      </Button>
    );
  };
  return (
    <div className="warningBorderr">
      <div className="warningg">
        {!localStorage.getItem("account") ? (
          <UnlockButton mt="8px" fullWidth handleClick={handleClick} />
        ) : (
          <>
            <p style={{ fontSize: "18px", fontWeight: 600 }}>
              Migrate to DDAO V2
            </p>
            <div style={{ display: "flex" }}>
              <Button
                mt="8px"
                className="tokenHarvestBorderAndColor button"
                disabled={modalUnstakeDisable}
                onClick={() => unStakeAllAmountAtOnce()}
                style={{ fontSize: "18px", fontWeight: 100 }}
              >
                {TranslateString(999, "Unstake")}
              </Button>
              {renderApprovalOrStakeForModal()}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UnstackAll;
