import React, { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { Button, Flex, Text, useModal } from "@pancakeswap-libs/uikit";
import useStake from "hooks/useStake";
import {
  getBalanceOfVempV2,
  getBalanceOfxVempV2,
  vempV2Earned,
} from "utils/VoteHarvest";
import DepositModal from "../DepositModal";
import WithdrawModal from "../WithdrawModal";
import VoteWarning from "./VoteWarning";
import VoteWithdrawWarning from "./VoteWithdrawWarning";

interface FarmCardActionsProps {
  stakedBalance?: BigNumber;
  tokenBalance?: BigNumber;
  tokenName?: string;
  pid?: number;
  depositFeeBP?: number;
  farm?: any;
  network?: boolean;
  webInstance: any;
  handleClick: any;
}

const StakeAction: React.FC<FarmCardActionsProps> = ({
  farm,
  tokenBalance,
  tokenName,
  pid,
  depositFeeBP,
  network,
  webInstance,
  handleClick,
}) => {
  const { onStake } = useStake(pid);
  const [val, setVal] = useState("");
  const [valWithdraw, setValWithdraw] = useState("0");
  const [xVempBalance, setxVempV2Balance] = useState("0");
  const [vempEarnded, setVempEarned] = useState<any>(0);
  const [userVempBalance, setUserVempBalance] = useState<any>();
  const [userXvempBalance, setUserxVempBalance] = useState<any>();
  const [onPresentDeposit] = useModal(
    <VoteWarning val={val} farm={farm} handleClick={handleClick} />
  );
  const [onPresentWithdraw] = useModal(
    <VoteWithdrawWarning
      val={valWithdraw}
      farm={farm}
      handleClick={handleClick}
    />
  );
  useEffect(() => {
    const getBalance = async () => {
      const v = await getBalanceOfxVempV2(farm.xVempV2Address, webInstance);
      const vempV2Earn = await vempV2Earned(
        farm.xVempV2Address,
        farm.ddaoV2Address,
        farm.vempV2Address,
        webInstance
      );

      setxVempV2Balance(v);
      setVempEarned(vempV2Earn);
    };
    if (farm.pid > 0) {
      getBalance();
    }
  }, [
    farm.xVempV2Address,
    farm.pid,
    farm.ddaoV2Address,
    farm.vempV2Address,
    webInstance,
  ]);

  const getBalance = useCallback(async () => {
    if (farm.pid > 0) {
      getBalanceOfVempV2(farm.vempV2Address, webInstance)
        .then((v) => setUserVempBalance(v))
        .catch();
    } else {
      setUserVempBalance(farm.depositFeeBP);
    }

    if (farm.pid > 0) {
      getBalanceOfxVempV2(farm.xVempV2Address, webInstance)
        .then((v) => setUserxVempBalance(v))
        .catch();
    } else {
      setUserxVempBalance(farm?.stakedAmount);
    }
  }, [
    farm.vempV2Address,
    farm.depositFeeBP,
    farm.pid,
    farm?.stakedAmount,
    farm.xVempV2Address,
    webInstance,
  ]);
  useEffect(() => {
    getBalance();
  });

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <DepositModal
          val={val}
          setVal={setVal}
          farm={farm}
          max={tokenBalance}
          onConfirm={onStake}
          tokenName={tokenName}
          depositFeeBP={depositFeeBP}
        />
        <Button
          className="tokenHarvestBorder button"
          onClick={onPresentDeposit}
          disabled={
            !network ||
            !(farm.pid > 0) ||
            new BigNumber(val) > new BigNumber(userVempBalance) ||
            !parseInt(val)
          }
        >
          Stake {farm?.lpSymbol}
        </Button>
      </div>
      <div>
        {farm.pid > 0 ? (
          <>
            <Flex justifyContent="space-between">
              <Text style={{ fontSize: "18px", fontWeight: 600 }}>
                Total VEMP
              </Text>
              <Text
                style={{ margin: "auto 0", fontSize: "18px", fontWeight: 600 }}
              >
                {vempEarnded.toFixed(3)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text style={{ fontSize: "18px", fontWeight: 600 }}>
                xVEMP Available
              </Text>
              <Text
                bold
                style={{ margin: "auto 0", fontSize: "18px", fontWeight: 600 }}
              >
                {xVempBalance?.toString()?.match(/^-?\d+(?:\.\d{0,3})?/)[0]}
              </Text>
            </Flex>
          </>
        ) : (
          ""
        )}

        <WithdrawModal
          val={valWithdraw}
          setVal={setValWithdraw}
          farm={farm}
          max={tokenBalance}
          onConfirm={onStake}
          tokenName={tokenName}
        />
        <Button
          className="tokenHarvestBorder button"
          style={{ padding: "0px" }}
          onClick={onPresentWithdraw}
          disabled={
            !network ||
            Number(valWithdraw) > Number(userXvempBalance) ||
            Number(valWithdraw) === 0
          }
        >
          Withdraw {farm?.lpSymbol}
        </Button>
      </div>
    </>
  );
};

export default StakeAction;
