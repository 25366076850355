import React from "react";
import { Svg, SvgProps } from "@pancakeswap-libs/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
      <path d="M292.571 80.014c0-20.198-16.374-36.571-36.571-36.571s-36.571 16.374-36.571 36.571v215.922h73.143V80.014zM444.652 229.682C485.158 203.667 512 158.221 512 106.502c0-33.261-11.105-63.919-29.802-88.487l-9.55 2.939c-3.94 84.795-44.483 109.337-92.427 114.518 29.856 24.145 52.565 56.787 64.431 94.21zM88.143 295.935h101.285V135.292c-59.533 25.82-101.285 85.155-101.285 154.071zM322.571 135.281v160.654h101.286v-6.572c0-68.917-41.751-128.261-101.286-154.082zM292.571 457.414v-28.336h-73.142v28.336L256 493.985zM67.351 229.684c11.868-37.425 34.58-70.066 64.44-94.21-47.95-5.178-88.499-29.717-92.44-114.519l-9.55-2.939C11.105 42.583 0 73.241 0 106.502c0 51.72 26.843 97.167 67.351 123.182zM54.857 325.935h402.286v73.143H54.857z" />
    </Svg>
  );
};

export default Icon;
