import {
  getPoolInfo,
  getUserInfo,
  getPendingVEMP,
  getLpPairAmount,
  getBnbBalanceOf,
  vempPerBlock,
} from "utils/farmHarvest";
import farmsConfig from "config/constants/farms";
import { createWebInstance } from "utils/wallectConnectUtility";

const fetchFarms = async () => {
    const instance = await createWebInstance()
    const data = await Promise.all(
      farmsConfig.map(async (farmConfig) => {
        const deposit = await getLpPairAmount(farmConfig, instance);
        const poolMultiplier = await getPoolInfo(
          farmConfig,
          farmConfig.cheffAddress,
          instance
        );
        const earnAmount = await getPendingVEMP(
          farmConfig,
          farmConfig.cheffAddress,
          farmConfig.lpSymbol,
          instance
        );
        const userStakedAmount = await getUserInfo(farmConfig, instance);
        const userBalance = await getBnbBalanceOf(instance);
        const vempBlock = await vempPerBlock(instance);

        return {
          ...farmConfig,
          multiplier: poolMultiplier,
          depositFeeBP: deposit,
          earnAmountFarm: earnAmount,
          stakedAmount: userStakedAmount,
          userEthBalance: userBalance,
          vempPerBlock: vempBlock,
        };
      })
    );
    return data;
};

export default fetchFarms;
