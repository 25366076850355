import React from "react";
import styled from "styled-components";
import {  Button } from "@pancakeswap-libs/uikit";
import Flex from "../components/Box/Flex";
import Box from "../components/Box/Box";
import ArrowBackIcon from "../icons/ArrowBackIcon";
import CloseIcon from "../icons/Close";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => background || "transparent"};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  display: flex;
  padding: 12px 24px;
  background: black;
  border-bottom: 1px solid #ffffff;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  background: linear-gradient(
    162.44deg,
    #000000 70.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
`;

export const ModalCloseButton: React.FC<{
  onDismiss: ModalProps["onDismiss"];
}> = ({ onDismiss }) => {
  return (
    <Button variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon style={{ color: "white" }} />
    </Button>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({
  onBack,
}) => {
  return (
    <Button variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="primary" />
    </Button>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 32px;
  width: 90%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};

  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    min-width: ${({ minWidth }) => minWidth};
    max-width: 100%;
  }

  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  border-radius: 20px;
  margin-bottom: 20px;
`;
