import React from "react";
import { Flex } from "@pancakeswap-libs/uikit";
import { HamburgerIcon } from "../icons";
import MenuButton from "./MenuButton";

interface Props {
  isPushed: boolean;
  isDark: boolean;
  togglePush: () => void;
  href: string;
}


const Logo: React.FC<Props> = ({ isPushed, togglePush }) => {
  return (
    <Flex>
      {isPushed ? (
        ""
      ) : (
        <MenuButton aria-label="Toggle menu" onClick={togglePush} mr="24px">
          <HamburgerIcon width="35px" height="20px" color="textSubtle" />
        </MenuButton>
      )}
    </Flex>
  );
};

export default React.memo(
  Logo,
  (prev, next) => prev.isPushed === next.isPushed && prev.isDark === next.isDark
);
