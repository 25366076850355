/* eslint-disable*/
import React, { useState } from "react";
import { Heading } from "@pancakeswap-libs/uikit";
import Page from "components/layout/Page";
import useI18n from "hooks/useI18n";
import styled from "styled-components";
import SoldierCard from "./SoldierCard";

const Div = styled.div`
  text-align: center;
  /* margin-top: 25px !important; */
  @media (max-width: 600px) {
    margin-top: 25px !important;
  } ;
`;

export interface SoldierProps {
  webInstance?: any;
  handleClick?: any;
}
const Soldier: React.FC<SoldierProps> = (soldierProps) => {
  const { webInstance,handleClick} = soldierProps;
  const TranslateString = useI18n();
  

  return (
    <Page>
      <Div>
        <Heading mb="20px" style={{ textAlign: "center", fontSize: "30px" }}>
          Stake Your Founding Soldiers NFTs
        </Heading>
        <Heading
          as="h1"
          color="secondary"
          mb="10px"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              paddingBottom: "20px",
            }}
          >
            After years of quietly building military strength and governing the
            surrounding areas, the time has come for the Empire to expand its
            borders, and seek out new places to rule.
          </p>
          <p
            style={{
              paddingBottom: "20px",
              lineHeight: "20px",
            }}
          >
            To stake your Soldiers, click on the NFT in the left hand box titled
            ‘Your Founding Soldiers. Once highlighted, the ID numbers for the
            Soldiers will appear in the box below.Once you’ve selected the NFTs
            to stake, press ‘Stake Soldiers.
          </p>
        </Heading>
        <SoldierCard handleClick={handleClick} />
      </Div>
    </Page>
  );
};

export default Soldier;
