import React from "react";
import { Button } from "@pancakeswap-libs/uikit";
import useI18n from "hooks/useI18n";
import useUserAccount from "hooks/useUserAccount";
import { useWalletModal } from "./CustomMenu/WalletModal";

const UnlockButton = (props) => {
  const TranslateString = useI18n();
  const { login, reset } = useUserAccount();
  const { onPresentConnectModal } = useWalletModal(login, reset,null,props?.handleClick);

  return (
    <Button
      className="tokenHarvestBorder button"
      onClick={onPresentConnectModal}
      {...props}
    >
      {TranslateString(292, "Unlock Wallet")}
    </Button>
  );
};

export default UnlockButton;
