/* eslint-disable import/no-cycle */

import Web3 from "web3";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { bnbNetworkId, ethNetworkId } from "config";
import cheffVempAbi from "../config/abi/masterchefVemp.json";
import erc20 from "../config/abi/erc20.json";
import bscContractABI from "../config/abi/bscContractAbi.json";
import { getMasterChefAddressVemp, getVempAddress } from "./addressHelpers";
import * as Addresses from "../config/constants/addresses";
import ddaoV2ABI from "../config/abi/version2ABI/ddaoV2.json";
import vempV2ABI from "../config/abi/version2ABI/vempV2.json";
import xVempV2ABI from "../config/abi/version2ABI/xVempV2.json";
import { checkConnectedAndGetAddress, storeCorrectChain } from "./farmHarvest";
import {
  createWalletProvider,
  createWebInstance,
} from "./wallectConnectUtility";

declare const window: any;

const cheffAddress = getMasterChefAddressVemp();
const cheffvemp = getVempAddress();
const vempAddress = Addresses.VempAddress;
const xVempAddress = Addresses.XVempAddress;

export const fetchAccounts = () => {
  return new Promise((resolve) => {
    const ethAccounts = getAccounts();
    resolve(ethAccounts);
  });
};
export const hexToNumber = (hex) => {
  const web3 = new Web3(window?.ethereum);
  return web3?.utils.hexToNumber(hex);
};

export const evaluateChain = (chain) => {
  return String(chain)?.replace("0x", "");
};

export const getChainId = async () => {
  const walletType = localStorage.getItem("wallet");

  let chainId;
  try {
    if (window?.okxwallet && walletType === "OKX Wallet") {
      const web3 = new Web3(window?.okxwallet);
      chainId = await web3?.eth?.getChainId();
      storeCorrectChain(chainId);
    } else if (
      window?.ethereum &&
      (walletType === "Metamask" || walletType === "web3")
    ) {
      const web3 = new Web3(window?.ethereum);
      chainId = await web3?.eth?.getChainId();
      storeCorrectChain(chainId);
    } else if (
      localStorage.getItem("walletconnect") &&
      (walletType === "WalletConnect" || walletType === "Trust Wallet")
    ) {
      const provider: any = await createWalletProvider("walletconnect");
      storeCorrectChain(provider?.chainId);
      chainId = provider?.chainId;
    }
  } catch (e) {
    return "";
  }
  return hexToNumber(chainId);
};
export const chainChanged = async () => {
  const walletType = localStorage.getItem("wallet");

  if (window?.okxwallet && walletType === "OKX Wallet") {
    window?.okxwallet?.on("chainChanged", (chainId) => {
      if (chainId.includes("38") || chainId.includes("61")) {
        storeCorrectChain(bnbNetworkId);
      } else {
        storeCorrectChain(chainId);
      }
      window?.dispatchEvent(new Event("storage"));
    });
  } else if (
    window?.ethereum &&
    (walletType === "Metamask" || walletType === "web3")
  ) {
    window?.ethereum?.on("chainChanged", (chainId) => {
      if (chainId.includes("38") || chainId.includes("61")) {
        storeCorrectChain(bnbNetworkId);
      } else {
        storeCorrectChain(chainId);
      }
      window?.dispatchEvent(new Event("storage"));
    });
  } else if (
    localStorage.getItem("walletconnect") &&
    (walletType === "WalletConnect" || walletType === "Trust Wallet")
  ) {
    const provider: any = await createWalletProvider("walletconnect");
    provider.on("chainChanged", (chainId) => {
      storeCorrectChain(chainId);
      window?.dispatchEvent(new Event("storage"));
    });
  }
};

export const onDisconnect = async () => {
  const walletType = localStorage.getItem("wallet");
  if (window?.okxwallet && walletType === "OKX Wallet") {
    window?.okxwallet?.on("disconnect", () => {
      localStorage.clear();
      window?.dispatchEvent(new Event("storage"));
    });
  } else if (window?.ethereum && walletType === "Metamask") {
    window?.ethereum?.on("disconnect", () => {
      localStorage.clear();
      window?.dispatchEvent(new Event("storage"));
    });
  } else if (
    localStorage.getItem("walletconnect") &&
    (walletType === "WalletConnect" || walletType === "Trust Wallet")
  ) {
    const provider: any = await createWalletProvider("walletconnect");
    provider.on("disconnect", () => {
      localStorage.clear();
      window?.dispatchEvent(new Event("storage"));
    });
  }
};

export const accountChanged = async () => {
  const walletType = localStorage.getItem("wallet");

  if (
    localStorage.getItem("walletconnect") &&
    (walletType === "WalletConnect" || walletType === "Trust Wallet")
  ) {
    const provider: any = await createWalletProvider("walletconnect");
    localStorage.setItem("account", provider?.accounts[0]);
    window?.dispatchEvent(new Event("storage"));

    provider.on("accountsChanged", (a) => {
      if (a.length > 0) {
        localStorage.setItem("account", a[0]);
        window?.dispatchEvent(new Event("storage"));
      } else {
        localStorage.clear();
        window?.dispatchEvent(new Event("storage"));
      }
    });
  } else if (
    window?.ethereum &&
    (walletType === "Metamask" || walletType === "web3")
  ) {
    const web3 = new Web3(window?.ethereum);
    const wallet = await web3?.eth?.getAccounts();
    localStorage.setItem("account", wallet[0]);
    window?.dispatchEvent(new Event("storage"));
    window?.ethereum?.on("accountsChanged", (a) => {
      if (a.length > 0) {
        localStorage.setItem("account", a[0]);
        window?.dispatchEvent(new Event("storage"));
      } else {
        localStorage.clear();
        window?.dispatchEvent(new Event("storage"));
      }
    });
  } else if (window?.okxwallet && walletType === "OKX Wallet") {
    const web3 = new Web3(window?.okxwallet);
    const wallet = await web3?.eth?.getAccounts();
    localStorage.setItem("account", wallet[0]);
    window?.dispatchEvent(new Event("storage"));
    window?.okxwallet?.on("accountsChanged", (a) => {
      if (a.length > 0) {
        localStorage.setItem("account", a[0]);
        window?.dispatchEvent(new Event("storage"));
      } else {
        localStorage.clear();
        window?.dispatchEvent(new Event("storage"));
      }
    });
  }
};

export const getAccounts = async () => {
  try {
    const web3 = new Web3(window?.ethereum);
    const wallet = await web3?.eth?.getAccounts();
    return wallet;
  } catch (e) {
    return "";
  }
};

// Get user Viking balance

export const getBalanceOf = async (lpAddress, webInstance: any) => {
  try {
    const totalBalance = await webInstance?.eth?.getBalance(
      lpAddress,
      (err: any) => {
        if (err) {
          // console.log(err);
        }
      }
    );
    const totalUserBalance = webInstance?.utils?.fromWei(totalBalance, "ether");
    return totalUserBalance || 0;
  } catch (e) {
    return 0.0;
  }
};

// total liquidity

export const getTotalLiquidity = async (webInstance: any) => {
  try {
    if (cheffvemp) {
      const contract = new webInstance.eth.Contract(erc20, cheffvemp);
      let lpPairResponse = await contract.methods
        .balanceOf(cheffAddress)
        .call();
      lpPairResponse = (
        webInstance?.utils?.fromWei(lpPairResponse, "ether") || 0
      ).toFixed(2);
      return lpPairResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const DDAOV2VempBalanceTvl = async (webInstance: any) => {
  try {
    if (vempAddress) {
      const contract = new webInstance.eth.Contract(vempV2ABI, vempAddress);
      let lpPairResponse = await contract.methods
        .balanceOf(Addresses.ddaoV2Eth)
        .call();
      lpPairResponse = (lpPairResponse / 10 ** 18 || 0).toFixed(2);
      return lpPairResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const BscBalanceTvl = async () => {
  try {
    if (Addresses.VempAddress) {
      const binanceIntance: any = new Web3("https://bsc-dataseed.binance.org/");
      const contract = new binanceIntance.eth.Contract(
        bscContractABI,
        "0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955"
      );
      let pendingResponse = await contract.methods
        .balanceOf("0x7bdd633aab71ccfb348f3cf03392a63c093f311a") // Mainnet Address
        .call();
      pendingResponse = (pendingResponse / 10 ** 18 || 0).toFixed(2);
      return pendingResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Get percentage of total bnb and eth staked
export const getTotalBnbAndEthStaked = async () => {
  try {
    const mainnetInstance: any = new Web3(
      "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    );
    const EthContract = new mainnetInstance.eth.Contract(
      erc20,
      Addresses?.VempAddress
    );
    const binanceInstance: any = new Web3("https://bsc-dataseed.binance.org/");
    const BnbContract = new binanceInstance.eth.Contract(
      bscContractABI,
      "0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955"
    );

    const totalBNB = await BnbContract.methods
      .balanceOf("0x7BDD633aAb71cCFB348f3cf03392a63C093f311A") // Mainnet Address
      .call();

    const totalETH = await EthContract.methods
      .balanceOf("0x87821C7fE32753Ce37a293ED6E05bEf789BB968b") // mainnet
      .call();

    const totalStaked = parseFloat(totalBNB) + parseFloat(totalETH);
    const ethPercent = ((parseFloat(totalETH) / totalStaked) * 100).toFixed(3);
    const bnbPercent = ((parseFloat(totalBNB) / totalStaked) * 100).toFixed(3);
    return { ethPercent, bnbPercent };
  } catch (e) {
    return { ethPercent: 0, bnbPercent: 0 };
  }
};

export const TotalBnbStaked = async () => {
  try {
    const binanceInstance: any = new Web3("https://bsc-dataseed.binance.org/");
    const contract = new binanceInstance.eth.Contract(
      bscContractABI,
      "0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955"
    );
    let pendingResponse = await contract.methods
      .balanceOf(Addresses.bnbPoolAddress)
      .call();
    pendingResponse = (pendingResponse / 10 ** 18 || 0).toFixed(2);
    return pendingResponse;
  } catch (error) {
    return 0.0;
  }
};

export const getLpPairAmount = async (lpAddress, webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (lpAddress) {
      const contract = new webInstance.eth.Contract(erc20, lpAddress);
      let lpPairResponse = await contract.methods.balanceOf(account).call();
      lpPairResponse = (
        webInstance?.utils?.fromWei(lpPairResponse, "ether") || 0
      ).toFixed(2);
      return lpPairResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Get BNB balance

export const getBnbBalanceOf = async (webInstance: any) => {
  try {
    const account = await checkConnectedAndGetAddress();
    const totalBalance = await webInstance.eth.getBalance(
      account,
      (err: any) => {
        if (err) {
          // console.log(err);
        }
      }
    );
    const totalUserBalance = webInstance?.utils?.fromWei(totalBalance, "ether");
    return totalUserBalance || 0;
  } catch (e) {
    return 0.0;
  }
};

// Function to get userAddress

export const getBalanceVemp = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (cheffvemp) {
      const contract = new webInstance.eth.Contract(erc20, cheffvemp);

      const balanceof = await contract.methods.balanceOf(account).call();

      return webInstance?.utils?.fromWei(balanceof, "ether");
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Function to call donate function

export const stake = async (pId: number, amount, webInstance: any) => {
  try {
    if (cheffAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();

      const contract = new ethers.Contract(
        cheffAddress,
        cheffVempAbi,
        provider
      );

      const reqAmount = new BigNumber(amount)
        .times(new BigNumber(10).pow(18))
        .toString();
      const cheffResponse = await contract.deposit(pId, reqAmount);
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const approveVemp = async (lpAddress, webInstance: any) => {
  try {
    if (lpAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(lpAddress, erc20, provider);
      const cheffResponse = await contract.approve(
        cheffAddress,
        ethers.constants.MaxUint256
      );
      // .send({ from: account });
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const approveXVemp = async (webInstance: any) => {
  try {
    if (xVempAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(xVempAddress, erc20, provider);
      const cheffResponse = await contract.approve(
        cheffAddress,
        ethers.constants.MaxUint256
      );

      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

// Function to call withdraw amount function

export const withdraw = async (pid, amount, webInstance: any) => {
  try {
    if (cheffAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(
        cheffAddress,
        cheffVempAbi,
        provider
      );
      const reqAmount = new BigNumber(amount)
        .times(new BigNumber(10).pow(18))
        .toString();
      const cheffResponse = await contract.withdraw(pid, reqAmount);
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

// Function to get staked amount

export const getUserInfo = async (pId, webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();
      const contract = new webInstance.eth.Contract(cheffVempAbi, cheffAddress);
      const cheffResponse = await contract.methods
        .userInfo(pId, account)
        .call();
      const reqResult = new BigNumber(cheffResponse.amount)
        .div(new BigNumber(10).pow(18))
        .toString();
      return reqResult;
    }
    return "";
  } catch (error) {
    return "0.00";
  }
};

export const getVempPerBlock = async (webInstance: any) => {
  try {
    if (cheffAddress) {
      const contract = new webInstance.eth.Contract(cheffVempAbi, cheffAddress);
      const vempResponse = await contract.methods.xVEMPPerBlock().call();
      return vempResponse / 10 ** 18;
    }
    return "";
  } catch (error) {
    return "0.00";
  }
};

export const getAllowances = async (lpAddress, webInstance: any) => {
  const lpPairAddress = lpAddress;
  const account = await checkConnectedAndGetAddress();
  try {
    if (lpPairAddress) {
      const contract = new webInstance.eth.Contract(erc20, lpPairAddress);
      const allowanceResponse: any = await contract.methods
        .allowance(account, cheffAddress)
        .call();
      return allowanceResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getAllowancesXvemp = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (xVempAddress) {
      const contract = new webInstance.eth.Contract(erc20, xVempAddress);
      const allowanceResponse: any = await contract.methods
        .allowance(account, cheffAddress)
        .call();
      return allowanceResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getTotalVempStaked = async (webInstance: any) => {
  try {
    if (cheffAddress) {
      const contract = new webInstance.eth.Contract(cheffVempAbi, cheffAddress);

      let vempResponse = await contract.methods.totalVempStaked().call();
      vempResponse = vempResponse / 10 ** 18 || 0;

      return vempResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getDepositFees = async (pid, webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();
      const contract = new webInstance.eth.Contract(cheffVempAbi, cheffAddress);
      const depositFee = await contract.methods.userInfo(pid, account).call();

      return depositFee.amount;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// ################################## DDAO V2 ######################################

export const approveVempV2 = async (
  vempV2Address,
  ddaoV2Address,
  webInstance: any
) => {
  try {
    if (vempV2Address) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(vempV2Address, vempV2ABI, provider);
      const cheffResponse = await contract.approve(
        ddaoV2Address,
        ethers.constants.MaxUint256
      );

      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const approveXVempV2 = async (
  xVempV2Address,
  ddaoV2Address,
  webInstance: any
) => {
  try {
    if (xVempV2Address) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();

      const contract = new ethers.Contract(
        xVempV2Address,
        xVempV2ABI,
        provider
      );
      const cheffResponse = await contract.approve(
        ddaoV2Address,
        ethers.constants.MaxUint256
      );

      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};
export const getAllowancesVempV2 = async (
  vempV2Address,
  ddaoV2Address,
  webInstance: any
) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (vempV2Address) {
      const contract = new webInstance.eth.Contract(vempV2ABI, vempV2Address);
      const allowanceResponse: any = await contract.methods
        .allowance(account, ddaoV2Address)
        .call();
      return allowanceResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getAllowancesXvempV2 = async (
  xVempV2Address,
  ddaoV2Address,
  webInstance: any
) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (xVempV2Address) {
      const contract = new webInstance.eth.Contract(xVempV2ABI, xVempV2Address);
      const allowanceResponse: any = await contract.methods
        .allowance(account, ddaoV2Address)
        .call();
      return allowanceResponse;
    }

    return "";
  } catch (error) {
    return 0.0;
  }
};

export const stakeV2 = async (amount, ddaoV2Address, webInstance: any) => {
  try {
    if (cheffAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(ddaoV2Address, ddaoV2ABI, provider);
      const reqAmount = new BigNumber(amount)
        .times(new BigNumber(10).pow(18))
        .toString();
      const cheffResponse = await contract.enter(reqAmount);
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const withdrawV2 = async (amount, ddaoV2Address, webInstance: any) => {
  try {
    if (cheffAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(ddaoV2Address, ddaoV2ABI, provider);

      const reqAmount = new BigNumber(amount)
        .times(new BigNumber(10).pow(18))
        .toString();
      const cheffResponse = await contract.leave(reqAmount);

      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const getBalanceOfVempV2 = async (vempV2Address, webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (vempV2Address) {
      const contract = new webInstance.eth.Contract(vempV2ABI, vempV2Address);

      const balanceof = await contract.methods.balanceOf(account).call();

      return webInstance?.utils?.fromWei(balanceof, "ether");
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getBalanceOfxVempV2 = async (xVempV2Address, webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (xVempV2Address) {
      const contract = new webInstance.eth.Contract(xVempV2ABI, xVempV2Address);

      const balanceof = await contract.methods.balanceOf(account).call();

      return webInstance?.utils?.fromWei(balanceof, "ether");
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getBalanceOfDDAO = async (
  DDAOAddrss,
  vempV2Address,
  webInstance: any
) => {
  try {
    if (vempV2Address) {
      const contract = new webInstance.eth.Contract(vempV2ABI, vempV2Address);

      const balanceof = await contract.methods.balanceOf(DDAOAddrss).call();

      return webInstance?.utils?.fromWei(balanceof, "ether");
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const vempV2Earned = async (
  xVempV2Address,
  DDAOAddress,
  vempV2Address,
  webInstance: any
) => {
  const account = await checkConnectedAndGetAddress();
  try {
    if (xVempV2Address) {
      const contract = new webInstance.eth.Contract(xVempV2ABI, xVempV2Address);
      const vempOnVempDDAO = await getBalanceOfDDAO(
        DDAOAddress,
        vempV2Address,
        webInstance
      );
      const xVempTotalSupply = await contract.methods.totalSupply().call();
      let xVempAvailable = await contract.methods.balanceOf(account).call();
      xVempAvailable /= 10 ** 18;

      const vempEarned =
        (xVempAvailable * vempOnVempDDAO) / (xVempTotalSupply / 10 ** 18);
      return !Number.isNaN(Number(vempEarned)) ? vempEarned : 0;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const vempPerXvemp = async (
  xVempV2Address,
  DDAOAddress,
  vempV2Address,
  webInstance: any
) => {
  try {
    if (xVempV2Address) {
      const contract = new webInstance.eth.Contract(xVempV2ABI, xVempV2Address);
      const vempOnVempDDAO = await getBalanceOfDDAO(
        DDAOAddress,
        vempV2Address,
        webInstance
      );
      const xVempTotalSupply =
        (await contract.methods.totalSupply().call()) / 10 ** 18;

      const vempPerxVemp = vempOnVempDDAO / xVempTotalSupply;

      return vempPerxVemp.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Unstake for Modal -> DDAO V1 to DDAO V2

export const completeUnstake = async (webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();

      const MasterCheffcontract = new webInstance.eth.Contract(
        cheffVempAbi,
        cheffAddress
      );

      const amountToWithdraw = await MasterCheffcontract.methods
        .userInfo(0, account)
        .call();

      await withdraw(0, amountToWithdraw[0] / 10 ** 18, webInstance);
      return amountToWithdraw[0];
    }
    return "";
  } catch (error) {
    //
    return [];
  }
};

export const amountToWithdraw = async (webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();

      const MasterCheffcontract = new webInstance.eth.Contract(
        cheffVempAbi,
        cheffAddress
      );

      const amount = await MasterCheffcontract.methods
        .userInfo(0, account)
        .call();

      return amount[0];
    }
    return "";
  } catch (error) {
    return [];
  }
};
