/* eslint-disable*/
import { Button, Heading } from "@pancakeswap-libs/uikit";
import FlexLayout from "components/layout/Flex";
import { bnbNetworkId, changeableUri, changeWithUri } from "config";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  baseUri,
  claimablePleb,
  claimPleb,
  plebAllowance,
  plebApprove,
  plebStaked,
  plebTokenArray,
  showMessage,
  stakePlebToken,
  withdrawPlebToken,
} from "utils/farmHarvest";
import {
  createWalletProvider,
  createWebInstance,
} from "utils/wallectConnectUtility";

import CardHeader from "./CardHeader";

const FCard = styled.div`
  border: 5px solid #8d8f8e;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  margin-top: 24px;
  align-self: baseline;
  background: black;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 12px;
  position: relative;
  text-align: center;
  min-height: 250px;
  max-height: 720px;
  background: linear-gradient(
    162.44deg,
    #000000 49.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
  border: 3px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;

  img {
    border-radius: 10px;
    width: 95%;
    height: 230px;
    margin: auto;
  }
  .heading {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 18px;
  }
`;

const PCard = styled.div`
  border: 5px solid #8d8f8e;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  margin-top: 24px;
  align-self: baseline;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  text-align: center;
  min-height: 250px;
  max-height: 510px;
  overflow-y: scroll;
  background: linear-gradient(
    162.44deg,
    #000000 49.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
  border: 3px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;

  .imageGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    img {
      border-radius: 10px;
      width: 88%;
      height: 120px;
      margin: auto;
      margin-bottom: 14px;
    }
  }
`;

const StyledInputWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  display: flex;
  height: 36px;
  padding: 0 ${(props) => props.theme.spacing[3]}px;
  border: 2px solid white;
  margin-bottom: 10px;
  padding-right: 0px;
`;

const StyledInput = styled.input`
  width: 100%;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 18px;
  flex: 1;
  height: 56px;
  margin: 0;
  padding: 0;
  outline: none;
`;
interface PlebCardActionsProps {
  handleClick: any;
  webInstance?: any;
}
const PlebsCard: React.FC<PlebCardActionsProps> = ({
  handleClick,
  webInstance,
}) => {
  const [value, setValue] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [image, setImage] = useState("");
  const [plebArray, setPlebArray] = useState<any>();
  const [allowance, setAllowance] = useState(0);
  const [disable, setDisabled] = useState(false);
  const [collectLoading, setCollectLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [baseURI, setBaseURI] = useState<String>();
  const [claimable, setClaimable] = useState(0);
  const [plebDataArray, setPlebDataArray] = useState([]);
  const [plebStakedArray, setPlebStakedArray] = useState([]);
  const [plebStakedDataArray, setPlebStakedDataArray] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleWithdraw = (e) => {
    setWithdraw(e.target.value);
  };

  const d = async () => {
    const instance = await createWebInstance();
    const isApproved = await plebAllowance(instance);
    setAllowance(isApproved);
    const c = await claimablePleb(instance);
    setClaimable(c);
    const staked = await plebStaked(instance);
    setPlebStakedArray(staked);
    const plebs = await plebTokenArray(instance);
    setPlebArray(plebs);
    const uri = await baseUri(instance);
    setBaseURI(uri);
  };

  useEffect(() => {
    d();
    window.addEventListener("storage", d);
  }, [webInstance]);

  useEffect(() => {
    const a = [];
    if (plebArray?.length) {
      const arr = [];

      plebArray?.forEach((e, i) => {
        arr[i] = fetch(`${baseURI}${e}`);
      });
      Promise.allSettled(arr).then((value) => {
        const jsonArr = [];
        value?.forEach((mydata: any, i) => {
          jsonArr[i] = mydata?.value?.json();
        });
        Promise.allSettled(jsonArr).then((values) => {
          values?.forEach((json: any, i) => {
            a.push({
              id: i,
              NftId: plebStakedArray[i],
              imgURl: json?.value?.image?.replace(changeableUri, changeWithUri),
            });
          });
          setPlebDataArray(a);
        });
      });
    }
  }, [plebArray, baseURI]);

  useEffect(() => {
    const a = [];
    try {
      if (plebStakedArray?.length) {
        const arr = [];
        plebStakedArray?.forEach((e, i) => {
          arr[i] = fetch(`${baseURI}${e}`);
        });
        Promise.allSettled(arr).then((value) => {
          const jsonArr = [];
          value?.forEach((mydata: any, i) => {
            jsonArr[i] = mydata?.value?.json();
          });
          Promise.allSettled(jsonArr).then((values) => {
            values?.forEach((json: any, i) => {
              a.push({
                id: i,
                NftId: plebStakedArray[i],
                imgURl: json?.value?.image?.replace(
                  changeableUri,
                  changeWithUri
                ),
              });
            });
            setPlebStakedDataArray(a);
          });
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [plebStakedArray, baseURI]);

  const approve = async () => {
    setDisabled(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await plebApprove(instance);
    showMessage(a, handleClick);
    const isApproved = await plebAllowance(instance);
    setAllowance(isApproved);
    setDisabled(false);
  };

  const deposit = async () => {
    setDisabled(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await stakePlebToken(value, instance);
    showMessage(a, handleClick);
    d();
    setValue([]);
    setDisabled(false);
  };

  const claim = async () => {
    setCollectLoading(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await claimPleb(instance);
    showMessage(a, handleClick);
    d();
    setCollectLoading(false);
  };

  const withdrawF = async () => {
    setUnstakeLoading(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);

    const a = await withdrawPlebToken(withdraw, instance);
    showMessage(a, handleClick);
    d();
    setWithdraw([]);
    setUnstakeLoading(false);
  };

  const handleNftWithdraw = (data) => {
    // Find index of specific object using findIndex method.
    const objIndex = withdraw?.findIndex((obj) => obj === data?.NftId);

    if (plebStakedDataArray[data?.id].selected) {
      const a = withdraw;
      a.splice(objIndex, 1);
      setWithdraw(a);
    } else {
      const a = withdraw;
      a.push(data?.NftId);
      setWithdraw(a);
    }

    const newArray = plebStakedDataArray?.map((item: any) => {
      if (item.NftId === data.NftId) {
        /* eslint-disable*/
        item.selected = !item.selected;
      }
      return item;
    });

    setPlebStakedDataArray(newArray);
  };

  const handleNftStake = (data) => {
    // Find index of specific object using findIndex method.
    const objIndex = value?.findIndex((obj) => obj === data?.NftId);

    if (plebDataArray[data?.id].selected) {
      const a = value;
      a.splice(objIndex, 1);
      setValue(a);
    } else {
      const a = value;
      a.push(data?.NftId);
      setValue(a);
    }

    const newArray = plebDataArray?.map((item: any) => {
      if (item.NftId === data.NftId) {
        /* eslint-disable*/
        item.selected = !item.selected;
      }
      return item;
    });

    setPlebDataArray(newArray);
  };

  return (
    <FlexLayout>
      <PCard>
        <h1 style={{ fontWeight: 800, fontSize: "21px", margin: "auto" }}>
          Your Plebs
        </h1>
        <div className="imageGroup">
          {plebDataArray &&
            plebDataArray?.map((data) =>
              data?.imgURl ? (
                <img
                  style={{
                    boxShadow: `${
                      data?.selected ? "0px 0px 4px 3px #ffffff" : ""
                    }`,
                  }}
                  src={data?.imgURl}
                  alt="not found"
                  onClick={() => handleNftStake(data)}
                />
              ) : (
                <span
                  style={{ width: "30px", height: "30px", margin: "10px" }}
                  className="loader"
                />
              )
            )}
        </div>
      </PCard>
      <FCard>
        <CardHeader plebStakedArray={plebStakedArray} plebArray={plebArray} />
        <div>
          <div>
            <p className="heading">Pleb ID Number</p>
            <StyledInputWrapper>
              <StyledInput
                value={value}
                onChange={handleChange}
                disabled={true}
              />
            </StyledInputWrapper>

            {allowance ? (
              <Button
                className="tokenHarvestBorder button"
                style={{ padding: "0px" }}
                onClick={() => deposit()}
                disabled={
                  localStorage.getItem("chainId") !== bnbNetworkId || disable
                }
              >
                {!disable ? `Stake Plebs` : <span className="loader" />}
              </Button>
            ) : (
              <Button
                className="tokenHarvestBorder button"
                style={{ padding: "0px" }}
                onClick={() => approve()}
                disabled={
                  disable || localStorage.getItem("chainId") !== bnbNetworkId
                }
              >
                {!disable ? `Approve` : <span className="loader" />}
              </Button>
            )}
          </div>
          <div>
            <div className="tokenHarvestBorder">
              <Heading className="tokenHarvestHeading" color="textDisabled">
                {claimable}
              </Heading>
            </div>
            <Button
              className="tokenHarvestBorder button"
              style={{ padding: "0px" }}
              onClick={() => claim()}
              disabled={
                localStorage.getItem("chainId") !== bnbNetworkId ||
                collectLoading
              }
            >
              {!collectLoading ? `Collect VEMP` : <span className="loader" />}
            </Button>
          </div>
          <div>
            <StyledInputWrapper>
              <StyledInput
                value={withdraw}
                onChange={handleWithdraw}
                disabled={true}
              />
            </StyledInputWrapper>
            <Button
              className="tokenHarvestBorder button"
              style={{ padding: "0px" }}
              onClick={() => withdrawF()}
              disabled={
                localStorage.getItem("chainId") !== bnbNetworkId ||
                unstakeLoading
              }
            >
              {!unstakeLoading ? `Unstake NFTs` : <span className="loader" />}
            </Button>
          </div>
        </div>
        <p style={{ fontWeight: 400, fontSize: "16px", marginTop: "12px" }}>
          Paid once a week
        </p>
      </FCard>
      <PCard>
        <h1 style={{ fontWeight: 800, fontSize: "21px", margin: "auto" }}>
          Staked Plebs
        </h1>
        <div className="imageGroup">
          {plebStakedDataArray?.map((data) => {
            /* eslint-disable*/
            return data?.imgURl ? (
              <img
                style={{
                  boxShadow: `${
                    data?.selected ? "0px 0px 4px 3px #ffffff" : ""
                  }`,
                }}
                src={data?.imgURl}
                alt="not found"
                onClick={() => handleNftWithdraw(data)}
              />
            ) : (
              <span
                style={{ width: "30px", height: "30px", margin: "10px" }}
                className="loader"
              />
            );
          })}
        </div>
      </PCard>
    </FlexLayout>
  );
};

export default PlebsCard;
