import React, { useState } from "react";
import { Button, Flex, Heading } from "@pancakeswap-libs/uikit";
import { stake, stakeV2 } from "utils/VoteHarvest";
import {createWalletProvider, createWebInstance } from "utils/wallectConnectUtility";
import "../../Farms/components/css/Card.css";


const HarvestAction = ({ farm, earnings, pid }) => {
  const [pendingTx, setPendingTx] = useState(false);

  const handlStake = async () => {
    const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);

    if (farm.pid > 0) {
      setPendingTx(true);
      await stakeV2(0, farm.ddaoV2Address,instance);
      setPendingTx(false);
    } else {
      setPendingTx(true);
      await stake(pid, 0,instance);
      setPendingTx(false);
    }
  };

  return (
    <>
      <Flex
        mb="8px"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
      >
        <div className="tokenHarvestBorder">
          <Heading
            style={{ fontWeight: 600, fontSize: "18px" }}
            color={earnings === 0 ? "textDisabled" : "text"}
          >
            {earnings ? earnings.toFixed(3) : earnings}
          </Heading>
        </div>
        <Button
          style={{ fontWeight: 100, fontSize: "18px" }}
          className="button"
          disabled={earnings === 0 || pendingTx}
          onClick={() => handlStake()}
        >
          Collect VEMP
          {!pendingTx?"Collect VEMP":<span className="loader"/>}
        </Button>
      </Flex>
    </>
  );
};

export default HarvestAction;
