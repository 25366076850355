import React, { useState } from "react";
import ModalActions from "components/ModalActions";
import { Button } from "@pancakeswap-libs/uikit";
import "../../Farms/components/css/Card.css";
import useI18n from "hooks/useI18n";
import { withdraw, withdrawV2 } from "utils/VoteHarvest";
import {
  createWalletProvider,
  createWebInstance,
} from "utils/wallectConnectUtility";
import { showMessage } from "utils/farmHarvest";

interface DepositModalProps {
  onDismiss?: () => void;
  farm?: any;
  val?: string;
  handleClick?: string;
}

const VoteWithdrawWarning: React.FC<DepositModalProps> = ({
  val,
  farm,
  onDismiss,
  handleClick,
}) => {
  const [pendingTx, setPendingTx] = useState(false);
  const TranslateString = useI18n();
  const handlWithdraw = async () => {
    const walletType = localStorage.getItem("wallet");
    const instance = await createWalletProvider(walletType);
    if (farm.pid > 0) {
      setPendingTx(true);
      const response = await withdrawV2(val, farm.ddaoV2Address, instance);
      showMessage(response, handleClick);
      setPendingTx(false);
      onDismiss();
      setTimeout(() => {
        window?.dispatchEvent(new Event("storage"));
      }, 3000);
    } else {
      setPendingTx(true);
      const response = await withdraw(farm.pid, val, instance);
      showMessage(response, handleClick);
      setPendingTx(false);
      onDismiss();
    }
  };
  return (
    <div className="warningBorder">
      <div className="warning">
        <h1 className="warningHeading" style={{ textDecoration: "underline" }}>
          INFORMATION
        </h1>
        <p className="warningParagraph">
          BY STAKING VEMP TO XVEMP YOU WILL QUALIFY TO BE GIFTED FEES FROM THE
          VEMPIRE PROTOCOL. AFTER DEPOSITING VEMP, YOU WILL RECEIVE, IN RETURN,
          XVEMP. AT THE TIME OF SWAPPING YOUR XVEMP BACK, THIS RETURNS YOUR
          INITIAL DEPOSIT , PLUS THE VEMP GIFTED OVER TIME. YOU CAN STAKE OR
          UNSTAKE TO AND FROM THE DDAO AT ANY TIME.
        </p>
        <ModalActions>
          <Button variant="secondary" onClick={onDismiss}>
            {TranslateString(462, "Cancel")}
          </Button>
          <Button disabled={pendingTx} onClick={() => handlWithdraw()}>
            {pendingTx
              ? TranslateString(488, "Pending Confirmation")
              : TranslateString(464, "Confirm")}
          </Button>
        </ModalActions>
      </div>
    </div>
  );
};

export default VoteWithdrawWarning;
