import React, { useEffect, useCallback } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import BigNumber from "bignumber.js";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { provider } from "web3-core";
import { BLOCKS_PER_YEAR, bnbNetworkId } from "config";
import FlexLayout from "components/layout/Flex";
import Page from "components/layout/Page";
import { useVotes, usePriceBnbBusd, usePriceCakeBusd } from "state/hooks";
import useRefresh from "hooks/useRefresh";
import { fetchVoteUserDataAsync } from "state/actions";
import { QuoteToken } from "config/constants/types";
import { createWebInstance } from "utils/wallectConnectUtility";
import VoteCard, { FarmWithStakedValue } from "./VoteCard/VoteCard";

export interface FarmsProps {
  tokenMode?: boolean;
  webInstance: any;
  handleClick: any;
}

const Vote: React.FC<FarmsProps> = (farmsProps) => {
  const [instance, setInstance] = React.useState<any>();
  const { path } = useRouteMatch();
  const farmsLP = useVotes();
  const cakePrice = usePriceCakeBusd();
  const bnbPrice = usePriceBnbBusd();
  const { account, ethereum }: { account: string; ethereum: provider } =
    useWallet();
  const { tokenMode, handleClick } = farmsProps;
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const setWebInstance = async () => {
      const instanceValue = await createWebInstance();
      setInstance(instanceValue);
    };
    setWebInstance();
    window.addEventListener("storage", setWebInstance);
  }, []);

  useEffect(() => {
    if (account) {
      dispatch(fetchVoteUserDataAsync(account));
    }
    window.addEventListener("storage", () => {
      if (account) {
        dispatch(fetchVoteUserDataAsync(account));
      }
    });
  }, [account, dispatch, fastRefresh]);

  const activeFarms = farmsLP.filter(
    (farm) => !!farm.isTokenOnly === !!tokenMode && farm.multiplier !== "0X"
  );
  const inactiveFarms = farmsLP.filter(
    (farm) => !!farm.isTokenOnly === !!tokenMode && farm.multiplier === "0X"
  );
  const stakedOnlyFarms = activeFarms;

  const farmsList = useCallback(
    (farmsDisplay, removed: boolean) => {
      const farmsToDisplayWithAPY: FarmWithStakedValue[] = farmsDisplay.map(
        (farm) => {
          const cakeRewardPerBlock = new BigNumber(farm.vikingPerBlock || 1)
            .times(new BigNumber(farm.poolWeight))
            .div(new BigNumber(10).pow(18));
          const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR);

          let apy = cakePrice.times(cakeRewardPerYear);

          let totalValue = new BigNumber(farm.lpTotalInQuoteToken || 0);

          if (farm.quoteTokenSymbol === QuoteToken.BNB) {
            totalValue = totalValue.times(bnbPrice);
          }

          if (totalValue.comparedTo(0) > 0) {
            apy = apy.div(totalValue);
          }

          return { ...farm, apy };
        }
      );

      const DisplayCardsAccordingToChainId = (farm) => {
        if (
          localStorage.getItem("chainId") === (bnbNetworkId && farm.network)
        ) {
          return (
            <VoteCard
              key={farm.pid}
              farm={farm}
              removed={removed}
              bnbPrice={bnbPrice}
              cakePrice={cakePrice}
              ethereum={ethereum}
              account={account}
              webInstance={instance}
              handleClick={handleClick}
            />
          );
        }
        if (
          localStorage.getItem("chainId") !== bnbNetworkId &&
          farm.network !== bnbNetworkId
        ) {
          return (
            <VoteCard
              key={farm.pid}
              farm={farm}
              removed={removed}
              bnbPrice={bnbPrice}
              cakePrice={cakePrice}
              ethereum={ethereum}
              account={account}
              webInstance={instance}
              handleClick={handleClick}
            />
          );
        }
        return "";
      };

      return farmsToDisplayWithAPY.map((farm) => (
        <>{DisplayCardsAccordingToChainId(farm)}</>
      ));
    },

    /* eslint-disable react-hooks/exhaustive-deps */
    [cakePrice, bnbPrice, ethereum, account, instance]
  );

  return (
    <Page style={{ width: "100%", marginTop: "-62px" }}>
      <FlexLayout>
        <Route exact path={`${path}`}>
          {farmsList(stakedOnlyFarms, false)}
        </Route>
        <Route exact path={`${path}/history`}>
          {farmsList(inactiveFarms, true)}
        </Route>
      </FlexLayout>
    </Page>
  );
};

export default Vote;
