import { LinkStatus } from "./types";

export const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "failure",
  },
  SOON: <LinkStatus>{
    text: "SOON",
    color: "warning",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "success",
  },
};

export const socials = [
];

export const MENU_HEIGHT = 64;
export const MENU_ENTRY_HEIGHT = 45;
export const SIDEBAR_WIDTH_FULL = 200;
export const SIDEBAR_WIDTH_REDUCED = 50;
