import { bnbNetworkId } from "config";
import contracts from "./contracts";
import { VoteConfig, QuoteToken } from "./types";

const votes: VoteConfig[] = [
  {
    pid: 1,
    risk: 5,
    lpSymbol: "DDAO",
    lpAddresses: "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec",
    network: "1",
    tokenSymbol: "/images/ddao.png",
    tokenAddresses: {
      97: "",
      56: "0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    ddaoV2Address: "0x87821C7fE32753Ce37a293ED6E05bEf789BB968b",
    xVempV2Address: "0x9Fc889FF92CbeEB12f8B70Be8F0e7F329F12d2B5",
    vempV2Address: "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec",
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: "BSC DDAO",
    lpAddresses: "0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec",
    network: bnbNetworkId,
    tokenSymbol: "/images/ddao.png",
    tokenAddresses: {
      97: "",
      56: "0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    ddaoV2Address: "0x7BDD633aAb71cCFB348f3cf03392a63C093f311A",
    xVempV2Address: "0x4a4E2D4859af854d2a3fc6Df295843D21E70CC78",
    vempV2Address: "0xedf3ce4dd6725650a8e9398e5c6398d061fa7955",
  },
];

export default votes;
