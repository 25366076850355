/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vEmpireCardDetail: [],
  provider: {},
  webInstance: {},
  showUserName: false,
  isWebInstance: false,
};

export const detailSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    setDetailedData: (state, action) => {
      state.vEmpireCardDetail = action.payload;
    },
    setProvider: (state: any, action) => {
      state.provider = action.payload;
    },
    setShowUserName: (state: any, action) => {
      state.showUserName = action.payload;
    },
    setWebInstance: (state: any, action) => {
      state.webInstance = action.payload;
    },
    setIsWebInstance: (state: any, action) => {
      state.isWebInstance = action.payload;
    },
  },
});

// Actions
export const {
  setDetailedData,
  setProvider,
  setWebInstance,
  setIsWebInstance,
  setShowUserName,
} = detailSlice.actions;

// Thunk

export const fetchDetailPublicDataAsync = () => async (dispatch, detail) => {
  dispatch(setDetailedData(detail));
};

export default detailSlice.reducer;
