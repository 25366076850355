import React from "react";
import styled from "styled-components";
import {  Link } from "react-router-dom";
import { Button } from "@pancakeswap-libs/uikit";
import useI18n from "hooks/useI18n";
import "../../Farms/components/css/Card.css";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 32px;
  a {
    font-weight: 100;
    color: #eae3fb;
    border: 1px solid #ffffff;
    border-radius: 22px;
    margin-right: 10px;
    background-color: #27262c;
    @media (max-width: 450px) {
      min-width: auto !important;
    }
  }
`;
const VoteTabButtons = () => {
  const TranslateString = useI18n();
  return (
    <Wrapper>
      <Button
        className="button"
        style={{ fontSize: "18px", padding: "0px", minWidth: "230px" }}
        as={Link}
        to="/makeProposal" /* onClick={handleMakeProposal} */
      >
        {TranslateString(698, "Make a Proposal")}
      </Button>
      <Button
        className="button "
        style={{ minWidth: "220px", fontSize: "18px", padding: "0px" }}
        as={Link}
        to="/history"
      >
        {TranslateString(700, "Proposal overview")}
      </Button>
    </Wrapper>
  );
};

export default VoteTabButtons;