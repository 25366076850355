import React from "react";
import { Svg, SvgProps } from "@pancakeswap-libs/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M1 24.5H42M1 1H42M1 12.5H42"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Icon;
