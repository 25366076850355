import React, { useMemo, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { provider } from "web3-core";
import { getContract } from "utils/erc20";
import { Button, Flex, Text, useModal } from "@pancakeswap-libs/uikit";
import {
  approveVemp,
  approveXVemp,
  getAllowances,
  getAllowancesXvemp,
  approveXVempV2,
  approveVempV2,
  getAllowancesVempV2,
  getAllowancesXvempV2,
} from "utils/VoteHarvest";
import { Farm } from "state/types";
import { useVoteFromPid, useVoteUser } from "state/hooks";
import useI18n from "hooks/useI18n";
import UnlockButton from "components/UnlockButton";
import  {createWalletProvider, createWebInstance } from "utils/wallectConnectUtility";
import { showMessage } from "utils/farmHarvest";
import StakeAction from "./StakeAction";
import HarvestAction from "./HarvestAction";
import "../css/Vote.css";
import UnstackAll from "./UnstackAll";

const Action = styled.div`
  padding-top: 16px;
`;
export interface FarmWithStakedValue extends Farm {
  apy?: BigNumber;
  ddaoV2Address?: string;
  xVempV2Address?: string;
  vempV2Address?: string;
}

interface FarmCardActionsProps {
  farm: FarmWithStakedValue;
  ethereum?: provider;
  account?: string;
  webInstance?: any;
  handleClick?: any;
}

const CardActions: React.FC<FarmCardActionsProps> = ({
  farm,
  ethereum,
  webInstance,
  handleClick,
}) => {
  const TranslateString = useI18n();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { lpAddresses, tokenAddresses, isTokenOnly, depositFeeBP } =
    useVoteFromPid(farm.pid);

  const { tokenBalance, stakedBalance } = useVoteUser(farm.pid);
  const lpAddress = lpAddresses[process.env.REACT_APP_CHAIN_ID];
  const tokenAddress = tokenAddresses[process.env.REACT_APP_CHAIN_ID];
  const [approveAllowance, setApproveAllowance] = React.useState<number>(0);
  const [approveXVempAllowance, setApproveXVempAllowance] =
    React.useState<number>(0);

  const lpName = farm.lpSymbol.toUpperCase();
  const isApproved = approveAllowance > 1;
  const isXVempApprove = approveXVempAllowance > 1;
  const network = localStorage.getItem("correctNetwork") === "true";

  // #########  DDAO Version 2 Approval
  const [approveAllowanceVempV2, setApproveAllowanceVempV2] =
    React.useState<number>(0);
  const [approveAllowanceXvempV2, setApproveAllowanceXvempV2] =
    React.useState<number>(0);
  const isApprovedVempV2 = approveAllowanceVempV2 > 1;
  const isXVempApproveXVempV2 = approveAllowanceXvempV2 > 1;

  const onAllowanceOfV2 = useCallback(async () => {
    if (webInstance) {
      const isXvempAllowance: any = await getAllowancesXvempV2(
        farm?.xVempV2Address,
        farm?.ddaoV2Address,
        webInstance
      );

      setApproveAllowanceXvempV2(isXvempAllowance);
    }
    const isAllowance: any = await getAllowancesVempV2(
      farm?.vempV2Address,
      farm?.ddaoV2Address,
      webInstance
    );
    setApproveAllowanceVempV2(isAllowance);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    webInstance,
    farm?.ddaoV2Address,
    farm?.vempV2Address,
    farm?.xVempV2Address,
  ]);

  React.useEffect(() => {
    onAllowanceOfV2();
  }, [onAllowanceOfV2]);

  const handleApproveVempV2 = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);
      const response = await approveVempV2(
        farm.vempV2Address,
        farm.ddaoV2Address,
        instance
      );
      showMessage(response, handleClick);
      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, [farm.vempV2Address, farm.ddaoV2Address]);

  const handleApproveXvempV2 = useCallback(async () => {
    try {
      const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);
      setRequestedApproval(true);
      const response = await approveXVempV2(farm.xVempV2Address, farm.ddaoV2Address, instance);
      showMessage(response, handleClick);
      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, [farm.xVempV2Address, farm.ddaoV2Address]);

  const renderApprovalOrStakeButtonV2 = () => {
    return isApprovedVempV2 && isXVempApproveXVempV2 ? (
      <StakeAction
        stakedBalance={stakedBalance}
        farm={farm}
        tokenBalance={tokenBalance}
        tokenName={lpName}
        pid={farm.pid}
        depositFeeBP={depositFeeBP}
        network={network}
        webInstance={webInstance}
        handleClick={handleClick}
      />
    ) : (
      renderVempApprovalButtonV2()
    );
  };
  const renderVempApprovalButtonV2 = () => {
    return !isApprovedVempV2 ? (
      <Button
        m="0px"
        mt="8px"
        mb="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={!network || requestedApproval}
        onClick={handleApproveVempV2}
      >
        {!requestedApproval ? (
          `${TranslateString(999, "Approve VEMP")}`
        ) : (
          <span className="loader" />
        )}
      </Button>
    ) : (
      <Button
        mt="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={!network || requestedApproval}
        onClick={handleApproveXvempV2}
      >
        {!requestedApproval ? (
          `${TranslateString(999, "Approve xVEMP")}`
        ) : (
          <span className="loader" />
        )}
      </Button>
    );
  };

  // #########  DDAO Version 1 Approval
  React.useEffect(() => {
    const onAllowance = async () => {
      const isAllowance: any = await getAllowances(
        farm.lpAddresses,
        webInstance
      );
      const isXvempAllowance: any = await getAllowancesXvemp(webInstance);
      setApproveXVempAllowance(isXvempAllowance);
      setApproveAllowance(isAllowance);
    };
    onAllowance();
  }, [farm.lpAddresses, requestedApproval, webInstance]);

  const lpContract = useMemo(() => {
    if (isTokenOnly) {
      return getContract(ethereum as provider, tokenAddress);
    }
    return getContract(ethereum as provider, lpAddress);
  }, [ethereum, lpAddress, tokenAddress, isTokenOnly]);

  const handleVempApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);
      const response = await approveVemp(farm.lpAddresses, instance);
      showMessage(response, handleClick);
      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, [farm.lpAddresses]);

  const handleXVempApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const walletType = localStorage.getItem("wallet");
      const instance: any = await createWalletProvider(walletType);
      const response = await approveXVemp(instance);
      showMessage(response, handleClick);
      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const renderApprovalOrStakeButton = (disabled: boolean) => {
    return isApproved && isXVempApprove ? (
      <StakeAction
        stakedBalance={stakedBalance}
        farm={farm}
        tokenBalance={tokenBalance}
        tokenName={lpName}
        pid={farm.pid}
        depositFeeBP={depositFeeBP}
        network={network}
        webInstance={webInstance}
        handleClick={handleClick}
      />
    ) : (
      renderVempApprovalButton(disabled)
    );
  };
  const renderVempApprovalButton = (disabled) => {
    return !isApproved ? (
      <Button
        m="0px"
        mt="8px"
        mb="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={!network || disabled || requestedApproval}
        onClick={handleVempApprove}
      >
        {TranslateString(999, "Approve VEMP")}
      </Button>
    ) : (
      <Button
        mt="8px"
        className="tokenHarvestBorder button"
        fullWidth
        disabled={disabled || requestedApproval}
        onClick={handleXVempApprove}
      >
        {TranslateString(999, "Approve xVEMP")}
      </Button>
    );
  };

  const [onPresentApyModal] = useModal(
    <UnstackAll farm={farm} onAllowanceOfV2={onAllowanceOfV2} handleClick={handleClick} />
  );

  const connectedApproveAndStake = () => {
    if (!localStorage.getItem("account")) {
      return <UnlockButton mt="8px" fullWidth handleClick={handleClick} />;
    }
    if (farm.pid > 0) {
      return renderApprovalOrStakeButtonV2();
    }
    return renderApprovalOrStakeButton(true);
  };

  return (
    <Action>
      {connectedApproveAndStake()}

      {farm.pid === 0 ? (
        <>
          <Flex>
            <Text
              color="secondary"
              fontSize="18px"
              pr="3px"
              style={{ fontWeight: 600 }}
            >
              VEMP Earned
            </Text>
          </Flex>
          <HarvestAction
            farm={farm}
            earnings={farm.earnAmountFarm}
            pid={farm.pid}
          />
          <Button
            m="0px"
            mt="8px"
            mb="5px"
            className="button"
            onClick={onPresentApyModal}
            disabled={!network}
            style={{ fontSize: "18px", fontWeight: 100 }}
          >
            Migrate
          </Button>
        </>
      ) : (
        ""
      )}
    </Action>
  );
};

export default CardActions;
