import BigNumber from "bignumber.js/bignumber";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const main = true;

export const CAKE_PER_BLOCK = new BigNumber(1);
export const BLOCKS_PER_YEAR = new BigNumber(10512000);
export const BSC_BLOCK_TIME = 3;

export const CAKE_POOL_PID = 1;
export const bnbNetworkId = main ? "56" : "97";
export const ethNetworkId = main ? "1" : "5";

export const PINATA_BASE_URI =
  "https://v-empire.mypinata.cloud/ipfs/QmZGSS8bei5sgbm7DuBMRX9BgaCRHx7ydJbLz7Ptg6HGnu/";

export const changeableUri = "https://gateway.pinata.cloud/";
export const changeWithUri = "https://v-empire.mypinata.cloud/";
