import React, { useContext } from "react";
import { allLanguages } from "config/localisation/languageCodes";
import useUserAccount from "hooks/useUserAccount";
import { LanguageContext } from "contexts/Localisation/languageContext";
import useTheme from "hooks/useTheme";
import { Menu as UikitMenu } from "components/CustomMenu/Menu";
import { MenuEntry } from "../CustomMenu/Menu/types";



const Menu = (props) => {
  const { login, reset, account } = useUserAccount();
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { isDark, toggleTheme } = useTheme();

  const config1: MenuEntry[] = [
    {
      label: "Home",
      icon: "HomeIcon",
      href: "/",
    },
    {
      label: "Worlds",
      icon: "FarmIcon",
      href: "/worlds",
    },
    {
      label: "Worlds V2",
      icon: "FarmIcon",
      href: "/worldsV2",
    },
    {
      label: "Stake VEMP",
      icon: "PoolIcon",
      href: "/vote",
    },
    {
      label: "Stake Plebs",
      icon: "PlebIcon",
      href: "/plebs",
    },
    {
      label: "Stake Soldiers",
      icon: "SwordIcon",
      href: "/soldiers",
    },
  ];

  const config2: MenuEntry[] = [
    {
      label: "Home",
      icon: "HomeIcon",
      href: "/",
    },
    {
      label: "Worlds",
      icon: "FarmIcon",
      href: "/worlds",
    },
    {
      label: "Stake VEMP",
      icon: "PoolIcon",
      href: "/vote",
    },
    {
      label: "Stake Plebs",
      icon: "PlebIcon",
      href: "/plebs",
    },
    {
      label: "Stake Soldiers",
      icon: "SwordIcon",
      href: "/soldiers",
    },
  ];

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      links={(localStorage.getItem('chainId')==='56' || localStorage.getItem('chainId')==='97') ?config2:config1}
      priceLink="https://EtherScan.com/token/0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0"
      {...props}
    />
  );
};

export default Menu;
