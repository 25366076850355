import { bnbNetworkId, ethNetworkId } from "config";
import contracts from "./contracts";
import { FarmConfig, QuoteToken } from "./types";
import * as Addresses from "./addresses";

const farms: FarmConfig[] = [
  {
    pid: 4,
    risk: 3,
    lpSymbol: "ETH",
    lpAddresses: "",
    cheffAddress: Addresses.cheffEth,
    tokenSymbol: "/images/eth.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
    network: ethNetworkId,
  },
  {
    pid: 6,
    risk: 3,
    lpSymbol: "APE",
    lpAddresses: Addresses.apeAddress,
    cheffAddress: Addresses.apeStakingAddress,
    tokenSymbol: "/images/eth.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
    network: ethNetworkId,
  },
  {
    pid: 0,
    risk: 5,
    lpSymbol: "MANA",
    lpAddresses: Addresses.manaStake,
    cheffAddress: Addresses.masterChefMana,
    tokenSymbol: "/images/mana.png",
    tokenAddresses: {
      97: "",
      56: "0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
    network: ethNetworkId,
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: "STARLINK",
    lpAddresses: "0x8E6cd950Ad6ba651F6DD608Dc70e5886B1AA6B24",
    cheffAddress: Addresses.adminUpgradabilityStarLV2Address,
    tokenSymbol: "/images/starlink.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: true,
    network: ethNetworkId,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: "MANA",
    lpAddresses: Addresses.manaStake,
    cheffAddress: Addresses.manaPoolAddress,
    tokenSymbol: "/images/mana.png",
    tokenAddresses: {
      97: "",
      56: "0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: true,
    network: ethNetworkId,
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: "SAND",
    lpAddresses: Addresses.sand,
    cheffAddress: Addresses.masterChefSand,
    tokenSymbol: "/images/sand.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
    network: ethNetworkId,
  },
  {
    pid: 8,
    risk: 3,
    lpSymbol: "SAND",
    lpAddresses: Addresses.sand,
    cheffAddress: Addresses.sandPoolAddress,
    tokenSymbol: "/images/sand.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: true,
    network: ethNetworkId,
  },

  {
    pid: 5,
    risk: 3,
    lpSymbol: "BNB",
    network: bnbNetworkId,
    lpAddresses: "0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955",
    cheffAddress: Addresses.bnbPoolAddress,
    tokenSymbol: "/images/eth.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
  },
  {
    pid: 7,
    risk: 3,
    lpSymbol: "STARLINK",
    lpAddresses: "0x8E6cd950Ad6ba651F6DD608Dc70e5886B1AA6B24",
    cheffAddress: Addresses.starLink,
    tokenSymbol: "/images/starlink.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
    network: ethNetworkId,
  },
  {
    pid: 10,
    risk: 3,
    network: bnbNetworkId,
    lpSymbol: "BUSD",
    lpAddresses: Addresses.busdStakingAddress,
    cheffAddress: Addresses.busdAddress,
    tokenSymbol: "/images/eth.png",
    tokenAddresses: {
      97: "",
      56: "0x5ac52ee5b2a633895292ff6d8a89bb9190451587",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isTokenOnly: false,
  },
];

export default farms;
