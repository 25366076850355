import Metamask from "../icons/Metamask";
import WalletConnect from "../icons/WalletConnect";
import TrustWallet from "../icons/TrustWallet";
import mathWallet from "../icons/MathWallet";
import { Config, ConnectorNames } from "./types";

const connectors: Config[] = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.Injected,
        priority: 1,
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 2,
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 3,
    },
    {
        title: "Unstoppable",
    },
    {
        title: "OKX Wallet",
        icon: mathWallet,
    },
];

export default connectors;
export const connectorLocalStorageKey = "connectorIdv2";
export const walletLocalStorageKey = "wallet";