/* eslint-disable*/
import { Button, Heading } from "@pancakeswap-libs/uikit";
import FlexLayout from "components/layout/Flex";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  checkConnectedAndGetAddress,
  claimableSoldier,
  claimSoldier,
  showMessage,
  soldierAllowance,
  soldierApprove,
  soldierStaked,
  stakeSoldierToken,
  withdrawSoldierToken,
} from "utils/farmHarvest";
import {
  createWalletProvider,
  createWebInstance,
} from "utils/wallectConnectUtility";

import CardHeader from "./CardHeader";

const FCard = styled.div`
  border: 5px solid #8d8f8e;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  margin-top: 24px;
  align-self: baseline;
  background: black;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 12px;
  position: relative;
  text-align: center;
  min-height: 250px;
  max-height: 720px;
  background: linear-gradient(
    162.44deg,
    #000000 49.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
  border: 3px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;

  img {
    border-radius: 10px;
    width: 95%;
    height: 230px;
    margin: auto;
  }
  .heading {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 18px;
  }
`;

const PCard = styled.div`
  border: 5px solid #8d8f8e;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  margin-top: 24px;
  align-self: baseline;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  position: relative;
  text-align: center;
  min-height: 250px;
  max-height: 510px;
  overflow-y: scroll;
  background: linear-gradient(
    162.44deg,
    #000000 49.97%,
    rgba(0, 0, 0, 0) 98.44%
  );
  border: 3px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
  border-radius: 20px;

  .imageGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    img {
      border-radius: 10px;
      width: 92%;
      height: 175px;
      margin: auto;
      margin-bottom: 14px;
    }
  }
`;

const StyledInputWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  display: flex;
  height: 36px;
  padding: 0 ${(props) => props.theme.spacing[3]}px;
  border: 2px solid white;
  margin-bottom: 10px;
  padding-right: 0px;
`;

const StyledInput = styled.input`
  width: 100%;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 18px;
  flex: 1;
  height: 56px;
  margin: 0;
  padding: 0;
  outline: none;
`;
interface PlebCardActionsProps {
  handleClick: any;
}
const SoldierCard: React.FC<PlebCardActionsProps> = ({ handleClick }) => {
  const [value, setValue] = useState([]);
  const [depositNFTs, setDepositNFTs] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [withdrawNFTs, setWithdrawNFTs] = useState([]);
  const [soldierArray, setSoldierArray] = useState<any>();
  const [allowance, setAllowance] = useState(0);
  const [disable, setDisabled] = useState(false);
  const [collectLoading, setCollectLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [claimable, setClaimable] = useState(0);
  const [soldierDataArray, setSoldierDataArray] = useState([]);
  const [soldierStakedArray, setSoldierStakedArray] = useState([]);
  const [soldierStakedDataArray, setSoldierStakedDataArray] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleWithdraw = (e) => {
    setWithdraw(e.target.value);
  };

  const mintedSodierNFT = async () => {
    let nftArray = [];
    let onlyNFT = [];
    let id = 0;
    const account = await checkConnectedAndGetAddress();
    const tokenResponse = await fetch(
      `https://api.opensea.io/api/v1/assets?owner=${account}&collection_slug=vempire-the-founding-soldiers&order_direction=desc&limit=20&include_orders=false`
    );
    const nftData = await tokenResponse.json();
    nftData?.assets?.map((data) => {
      nftArray.push({
        id,
        NftId: data?.token_id,
        subNftId: data?.token_id.substr(data?.token_id.length - 5),
        imgURl: data?.image_thumbnail_url,
        selected: false,
      });
      onlyNFT.push(data?.token_id);
      id++;
    });
    setSoldierDataArray(nftArray);
    setSoldierArray(onlyNFT);
  };

  const stakedSodierNFT = async () => {
    const webInstance: any = await createWebInstance();
    const staked = await soldierStaked(webInstance);
    if (staked.length) {
      let nftArray = [];

      const a = "token_ids=";
      let n = [];
      let id = 0;
      let count = 0;
      let c = "";
      for (let id = 0; id < staked.length; id++) {
        if (count === 30) {
          n.push(c);
          count = 0;
          c = "";
        }

        c = c + a + staked[id];
        c = c + "&";
        count++;
      }
      n.push(c);

      let tokenResponse = [];
      n.map(async (val) => {
        let response = await fetch(
          `https://api.opensea.io/api/v1/assets?${val}collection_slug=vempire-the-founding-soldiers&order_direction=desc&limit=30&include_orders=false`
        );
        const res = await response.json();
        res?.assets?.map((data) => {
          nftArray.push({
            id,
            NftId: data?.token_id,
            subNftId: data?.token_id.substr(data?.token_id.length - 5),
            imgURl: data?.image_thumbnail_url,
            selected: false,
          });
          id++;
        });
      });
      setSoldierStakedDataArray(nftArray);
    }
  };

  const d = async () => {
    mintedSodierNFT();
    stakedSodierNFT();
    const webInstance: any = await createWebInstance();
    const isApproved = await soldierAllowance(webInstance);
    setAllowance(isApproved);
    const c = await claimableSoldier(webInstance);
    setClaimable(c);
    const staked = await soldierStaked(webInstance);
    setSoldierStakedArray(staked);
  };

  useEffect(() => {
    d();
    window.addEventListener("storage", d);
  }, []);

  const approve = async () => {
    setDisabled(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);

    const a = await soldierApprove(instance);
    showMessage(a, handleClick);
    const isApproved = await soldierAllowance(instance);
    setAllowance(isApproved);
    setDisabled(false);
  };

  const deposit = async () => {
    setDisabled(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await stakeSoldierToken(depositNFTs, instance);
    showMessage(a, handleClick);
    mintedSodierNFT();
    d();
    setValue([]);
    setDepositNFTs([]);
    setWithdraw([]);
    setWithdrawNFTs([]);
    setDisabled(false);
  };

  const claim = async () => {
    setCollectLoading(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await claimSoldier(instance);
    showMessage(a, handleClick);
    d();
    setCollectLoading(false);
  };

  const withdrawF = async () => {
    setUnstakeLoading(true);
    const walletType = localStorage.getItem("wallet");
    const instance: any = await createWalletProvider(walletType);
    const a = await withdrawSoldierToken(withdrawNFTs, instance);
    showMessage(a, handleClick);
    d();
    setValue([]);
    setDepositNFTs([]);
    setWithdraw([]);
    setWithdrawNFTs([]);
    setUnstakeLoading(false);
  };

  const handleNftWithdraw = (data) => {
    // Find index of specific object using findIndex method.
    const objIndex = withdraw?.findIndex((obj) => obj === data?.subNftId);
    const actualIndex = withdrawNFTs?.findIndex((obj) => obj === data?.NftId);

    if (soldierStakedDataArray[data?.id].selected) {
      const a = withdraw;
      const actualWithdrawArray = withdrawNFTs;
      a.splice(objIndex, 1);
      actualWithdrawArray?.splice(actualIndex, 1);
      setWithdraw(a);
      setWithdrawNFTs(actualWithdrawArray);
    } else {
      const a = withdraw;
      const actualWithdrawArray = withdrawNFTs;
      a.push(data?.NftId.substr(data?.NftId.length - 5));
      actualWithdrawArray.push(data?.NftId);
      setWithdraw(a);
      setWithdrawNFTs(actualWithdrawArray);
    }

    const newArray = soldierStakedDataArray?.map((item: any) => {
      if (item.NftId === data.NftId) {
        /* eslint-disable*/
        item.selected = !item.selected;
      }
      return item;
    });

    setSoldierStakedDataArray(newArray);
  };

  const handleNftStake = (data) => {
    // Find index of specific object using findIndex method.
    const objIndex = value?.findIndex((obj) => obj === data?.subNftId);
    const actualIndex = depositNFTs?.findIndex((obj) => obj === data?.NftId);

    if (soldierDataArray[data?.id].selected) {
      const a = value;
      const actualDepositArray = depositNFTs;
      a.splice(objIndex, 1);
      actualDepositArray.splice(actualIndex, 1);
      setValue(a);
      setDepositNFTs(actualDepositArray);
    } else {
      const a = value;
      const actualDepositArray = depositNFTs;
      a.push(data?.NftId.substr(data?.NftId.length - 5));
      actualDepositArray.push(data?.NftId);
      setValue(a);
      setDepositNFTs(actualDepositArray);
    }

    const newArray = soldierDataArray?.map((item: any) => {
      if (item.NftId === data.NftId) {
        /* eslint-disable*/
        item.selected = !item.selected;
      }
      return item;
    });

    setSoldierDataArray(newArray);
  };

  return (
    <FlexLayout>
      <PCard>
        <h1 style={{ fontWeight: 800, fontSize: "19px", margin: "auto" }}>
          Your Founding Soldiers
        </h1>
        <div className="imageGroup">
          {soldierDataArray &&
            soldierDataArray?.map((data, i) => (
              <img
                style={{
                  boxShadow: `${
                    data?.selected ? "0px 0px 4px 3px #ffffff" : ""
                  }`,
                }}
                src={data?.imgURl}
                alt="not found"
                onClick={() => handleNftStake(data)}
                key={i}
              />
            ))}
        </div>
      </PCard>
      <FCard>
        <CardHeader
          soldierStakedArray={soldierStakedArray}
          soldierArray={soldierArray}
        />
        <div>
          <div>
            <p className="heading">Soldiers ID Number</p>
            <StyledInputWrapper>
              <StyledInput
                value={value}
                onChange={handleChange}
                disabled={true}
              />
            </StyledInputWrapper>

            {allowance ? (
              <Button
                className="tokenHarvestBorder button"
                style={{ padding: "0px" }}
                onClick={() => deposit()}
                disabled={localStorage.getItem("chainId") !== "1" || disable}
              >
                {!disable ? `Stake Soldiers` : <span className="loader" />}
              </Button>
            ) : (
              <Button
                className="tokenHarvestBorder button"
                style={{ padding: "0px" }}
                onClick={() => approve()}
                disabled={disable || localStorage.getItem("chainId") !== "1"}
              >
                {!disable ? `Approve` : <span className="loader" />}
              </Button>
            )}
          </div>

          <div>
            <div className="tokenHarvestBorder">
              <Heading className="tokenHarvestHeading" color="textDisabled">
                {claimable}
              </Heading>
            </div>
            <Button
              className="tokenHarvestBorder button"
              style={{ padding: "0px" }}
              onClick={() => claim()}
              disabled={
                localStorage.getItem("chainId") !== "1" || collectLoading
              }
            >
              {!collectLoading ? `Collect VEMP` : <span className="loader" />}
            </Button>
          </div>
          <div>
            <StyledInputWrapper>
              <StyledInput
                value={withdraw}
                onChange={handleWithdraw}
                disabled={true}
              />
            </StyledInputWrapper>
            <Button
              className="tokenHarvestBorder button"
              style={{ padding: "0px" }}
              onClick={() => withdrawF()}
              disabled={
                localStorage.getItem("chainId") !== "1" || unstakeLoading
              }
            >
              {!unstakeLoading ? `Unstake NFTs` : <span className="loader" />}
            </Button>
          </div>
        </div>
        <p style={{ fontWeight: 400, fontSize: "16px", marginTop: "12px" }}>
          Paid once a week
        </p>
      </FCard>
      <PCard>
        <h1 style={{ fontWeight: 800, fontSize: "19px", margin: "auto" }}>
          Staked Founding Soldiers
        </h1>
        <div className="imageGroup">
          {soldierStakedDataArray &&
            soldierStakedDataArray?.map((data, i) => (
              /* eslint-disable*/
              <img
                style={{
                  boxShadow: `${
                    data?.selected ? "0px 0px 4px 3px #ffffff" : ""
                  }`,
                }}
                src={data?.imgURl}
                alt="not found"
                onClick={() => handleNftWithdraw(data)}
                key={i}
              />
            ))}
        </div>
      </PCard>
    </FlexLayout>
  );
};

export default SoldierCard;
