import { Overlay } from "components/Overlay";
import React, {  useState } from "react";
import styled from "styled-components";


const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.modal - 1};
`;

const Context = React.createContext(null);

const ModalProvider: React.FC = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalNode, setModalNode] = useState<React.ReactNode>();
    const [nodeId, setNodeId] = useState("");
    const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

    const handlePresent = (node: React.ReactNode, newNodeId: string) => {
        setModalNode(node);
        setIsOpen(true);
        setNodeId(newNodeId);
    };

    const handleDismiss = () => {
        setModalNode(undefined);
        setIsOpen(false);
        setNodeId("");
    };

    const handleOverlayDismiss = () => {
        if (closeOnOverlayClick) {
            handleDismiss();
        }
    };

    return (
        <Context.Provider
            value={{
                isOpen,
                nodeId,
                modalNode,
                setModalNode,
                onPresent: handlePresent,
                onDismiss: handleDismiss,
                setCloseOnOverlayClick,
            }}
        >
            {isOpen && (
                <ModalWrapper>
                    <Overlay show onClick={handleOverlayDismiss} />
                    {React.isValidElement(modalNode) &&
                        React.cloneElement(modalNode)}
                </ModalWrapper>
            )}
            {children}
        </Context.Provider>
    );
};

export { ModalProvider as default, Context };
