import React from "react";
import { Svg, SvgProps } from "@pancakeswap-libs/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      style={{ marginRight: "8px", marginLeft: "5px" }}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M13.796 4.13621C12.8136 3.12159 11.1863 3.12159 10.2039 4.1362L5.40546 9.09194C5.12987 9.37655 4.94469 9.73636 4.87323 10.126C4.29047 13.304 4.24745 16.5575 4.74599 19.7497L4.92249 20.8799C4.97824 21.2369 5.2857 21.5001 5.64701 21.5001H8.99997C9.27611 21.5001 9.49997 21.2762 9.49997 21.0001V14.0001H14.5V21.0001C14.5 21.2762 14.7238 21.5001 15 21.5001H18.3529C18.7142 21.5001 19.0217 21.2369 19.0774 20.8799L19.2539 19.7497C19.7524 16.5575 19.7094 13.304 19.1267 10.126C19.0552 9.73636 18.87 9.37655 18.5944 9.09194L13.796 4.13621Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
