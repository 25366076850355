import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { ToastContainer, useMatchBreakpoints } from "@pancakeswap-libs/uikit";
import Overlay from "components/Overlay/Overlay";
import { bnbNetworkId, ethNetworkId } from "config";
import Logo from "../components/Logo";
import Panel from "../components/Panel";
import UserBlock from "../components/UserBlock";
import { NavProps } from "./types";
import Avatar from "../components/Avatar";
import "./Menu.css";
import {
  MENU_HEIGHT,
  SIDEBAR_WIDTH_REDUCED,
  SIDEBAR_WIDTH_FULL,
} from "./config";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  padding-top: 10px;
  /* background-color: ${({ theme }) => theme.nav.background}; */
  background-color: transparent;
  /* border-bottom: solid 2px rgba(133, 133, 133, 0.1); */
  z-index: 20;
  transform: translate3d(0, 0, 0);

  .toggleWithConnect {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
    @media (min-width: 600px) {
      display: none;
    }
  }

  .temp {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

const ConnectB = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  /* margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)}; */
  margin-top: 20px;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  min-height: 100vh;

  ${({ theme }) => theme.mediaQueries.nav} {
    margin-left: ${({ isPushed }) =>
      `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    max-width: ${({ isPushed }) =>
      `calc(100% - ${
        isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED
      }px)`};
  }
`;

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;
  background: radial-gradient(
    164.47% 86.1% at 27.6% 25.03%,
    #12100e 0%,
    #2b4162 100%
  );
  backdrop-filter: blur(140.037px);
  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`;

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  profile,
  children,
}) => {
  const { isXl } = useMatchBreakpoints();
  const isMobile = isXl === false;
  const [isPushed, setIsPushed] = useState(!isMobile);
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(window.pageYOffset);
  const network = localStorage.getItem("correctNetwork") === "true";

  // For toaster
  const [toasts, setToasts] = useState([]);
  const handleClick = (description = "") => {
    const now = Date.now();
    const randomToast = {
      id: `id-${now}`,
      description,
    };
    setToasts((prevToasts) => [randomToast, ...prevToasts]);
  };

  const handleRemove = (id: string) => {
    setToasts((prevToasts) =>
      prevToasts.filter((prevToast) => prevToast.id !== id)
    );
  };

  useEffect(() => {
    const d = () => {
      handleClick("Wrong network, Please connect with correct network");
    };
    setTimeout(() => {
      const chainId = localStorage.getItem("chainId");
      if (!(chainId === ethNetworkId || chainId === bnbNetworkId)) {
        d();
      }
    }, 3000);
    window?.addEventListener("storage", (event) => {
      if (event.key === "chainId") {
        const chainId = localStorage.getItem("chainId");
        if (!(chainId === ethNetworkId || chainId === bnbNetworkId)) {
          d();
        }
      }
    });
  }, [network]);

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage =
        window.document.body.clientHeight ===
        currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu}>
        {isPushed ? (
          ""
        ) : (
          <>
            {!!login && !!logout && (
              <div className="toggleWithConnect">
                <Logo
                  isPushed={isPushed}
                  togglePush={() =>
                    setIsPushed((prevState: boolean) => !prevState)
                  }
                  isDark={isDark}
                  href={homeLink?.href ?? "/"}
                />
                <div>
                  <UserBlock
                    account={account}
                    login={login}
                    logout={logout}
                    handleClick={handleClick}
                  />
                  {profile && <Avatar profile={profile} />}
                </div>
              </div>
            )}
          </>
        )}
        <div className="temp" />
        <ConnectB>
          <UserBlock
            account={account}
            login={login}
            logout={logout}
            handleClick={handleClick}
          />
          {profile && <Avatar profile={profile} />}
        </ConnectB>
      </StyledNav>
      <BodyWrapper>
        <Panel
          isPushed={isPushed}
          isMobile={isMobile}
          showMenu={showMenu}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          currentLang={currentLang}
          cakePriceUsd={cakePriceUsd}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
        <MobileOnlyOverlay
          show={isPushed}
          onClick={() => setIsPushed(false)}
          role="presentation"
        />
        <ToastContainer toasts={toasts} onRemove={handleRemove} />
      </BodyWrapper>
    </Wrapper>
  );
};

export default Menu;
