/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */

import React from "react";
import styled from "styled-components";
import Web3 from "web3";
import { Button, Text } from "@pancakeswap-libs/uikit";
import { createWalletProvider } from "utils/wallectConnectUtility";
import { connectOkx, getAccounts, storeCorrectChain } from "utils/farmHarvest";
import { handleLoginButtonClick } from "utils/connectors";
import { bnbNetworkId} from "config";
import Unstoppable from "../icons/download.png";
import OkxWallet from "../icons/OKXLogo.png";
import { connectorLocalStorageKey, walletLocalStorageKey } from "./Config";
import { Login, Config } from "./types";

declare const window: any;

interface Props {
  walletConfig: Config;
  login: Login;
  onDismiss: () => void;
  handleClick: any;
}

const WalletButton = styled(Button).attrs({
  width: "100%",
  variant: "text",
  py: "16px",
})`
  align-items: center;
  display: grid;
  /* flex-direction: column; */
  gap: 10px;
  height: auto;
  grid-template-columns: 1fr 2fr;
  /* justify-content: space-around; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const MoreWalletCard = ({ ...props }) => {
  return (
    <WalletButton {...props}>
      <Text fontSize="14px">More</Text>
    </WalletButton>
  );
};

const WalletCard: React.FC<Props> = ({
  login,
  walletConfig,
  onDismiss,
  handleClick,
}) => {
  const { title, icon: Icon } = walletConfig;

  const connectWallet = async () => {
    const arr = ["trust wallet", "walletconnect"];
    // Since iOS does not support Trust Wallet we fall back to WalletConnect
    // removed the && isIOS for now
    if (arr.includes(walletConfig?.title?.toLocaleLowerCase())) {
      const provider: any = await createWalletProvider(walletConfig.title);
      if (provider?.accounts[0]) {
        localStorage.setItem("account", provider?.accounts[0]);
        storeCorrectChain(provider?.chainId);
        localStorage.setItem(walletLocalStorageKey, walletConfig.title);
        localStorage.setItem(
          connectorLocalStorageKey,
          walletConfig.connectorId
        );
      }
      provider?.on("accountsChanged", (accounts: string[]) => {
        if (provider?.accounts[0]) {
          localStorage.setItem("account", accounts[0]);
        }
        window?.dispatchEvent(new Event("storage"));
      });
      provider?.on("chainChanged", (chainId: string) => {
        storeCorrectChain(chainId);
        window?.dispatchEvent(new Event("storage"));
      });

      window?.dispatchEvent(new Event("storage"));
    } else if (walletConfig.title === "OKX Wallet") {
      await connectOkx(
        handleClick,
        onDismiss,
        walletLocalStorageKey,
        connectorLocalStorageKey,
        walletConfig
      );
    } else if (walletConfig.title === "Metamask") {
      if (window?.ethereum) {
        login(walletConfig.connectorId);
        const web3 = new Web3(window?.ethereum);
        const chain = await web3?.eth?.getChainId();
        storeCorrectChain(chain);
        window?.ethereum?.on("accountsChanged", (accounts: string[]) => {
          if (accounts[0]) {
            localStorage.setItem("account", accounts[0]);
          }
        });
        window?.ethereum?.on("chainChanged", (chainId) => {
          if (chainId.includes("38") || chainId.includes("61")) {
            storeCorrectChain(bnbNetworkId);
          } else {
            storeCorrectChain(chainId);
          }
          window?.dispatchEvent(new Event("storage"));
        });
        const account = await getAccounts();
        if (account?.length > 0) {
          localStorage.setItem("account", account[0]);
          localStorage.setItem(walletLocalStorageKey, walletConfig.title);
          localStorage.setItem(
            connectorLocalStorageKey,
            walletConfig.connectorId
          );
        }
        window?.dispatchEvent(new Event("storage"));
      } else {
        handleClick("Please install Metamask extension");
      }
    }
    onDismiss();
  };

  const handleConnect = (e) => {
    if (walletConfig.title !== "Unstoppable") {
      connectWallet();
    } else {
      handleLoginButtonClick(e);
      onDismiss();
    }
  };
  return (
    <div>
      <WalletButton
        onClick={handleConnect}
        id={`wallet-connect-${title?.toLocaleLowerCase()}`}
      >
        <Text fontSize="14px" style={{ width: "90px" }}>
          {title}
        </Text>
        {walletConfig?.title === "Unstoppable" ? (
          <img
            style={{ width: "40px", marginLeft: "100px", borderRadius: "10px" }}
            src={Unstoppable}
            alt=""
          />
        ) : walletConfig?.title === "OKX Wallet" ? (
          <img
            style={{ width: "40px", marginLeft: "100px", borderRadius: "10px" }}
            src={OkxWallet}
            alt=""
          />
        ) : (
          <Icon width="40px" ml="100px" mb="8px" />
        )}
      </WalletButton>
    </div>
  );
};

export default WalletCard;
