import React, { useState, useEffect } from "react";
import useUserAccount from "hooks/useUserAccount";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Login } from "@pancakeswap-libs/uikit";
import { useShowUserName } from "state/hooks";
import { handleLogoutButtonClick } from "utils/connectors";
import Unstoppable from "../icons/download.png";
import useWalletModal from "../WalletModal/useWalletModal";

const Button = styled.button`
  width: 118px;
  height: 32px;
  background-color: black !important;
  border-radius: 8px !important;
  border: 1.9px solid white !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0px;
`;

const Wallet = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background-color: black !important;
  border-radius: 8px !important;
  border: 1.9px solid white !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 5px 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 15px !important;
  }
`;

const Menu = styled.text`
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 8px !important;
  background: black;
  padding: 10px 10px;
  position: relative;
  top: 8px;
  gap: 10px;
  transition: 1s ease-out;
`;
const MenuButton = styled.button`
  background: black !important;
  font-weight: 900;
  font-size: 14px !important;
  border: none !important;
  cursor: pointer;
`;

interface Props {
  account?: string;
  login?: Login;
  logout?: () => void;
  handleClick?: any;
}

const UserBlock: React.FC<Props> = ({ login, logout,handleClick }) => {
  const dispatch = useDispatch();
  const showUserName = useShowUserName();
  const { account, reset } = useUserAccount();
  const [userName, setUserName] = useState("");
  const [menu, setMenu] = useState(false);

  const { onPresentAccountModal, onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account,
    handleClick
  );

  const getUserName = () => {
    const nameStr = JSON.parse(localStorage.getItem("username"));
    setUserName(nameStr?.value);
  };

  useEffect(() => {
    getUserName();
  }, [showUserName]);


  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;

  return (
    <div>
      {account ? (
        <>
          <div
            style={
              !!menu && !!userName
                ? { marginTop: "70px" }
                : { marginTop: "0px" }
            }
          >
            {!!userName && (
              <Wallet
                onClick={() => {
                  setMenu(!menu);
                }}
              >
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={Unstoppable}
                  alt=""
                />
                {userName}
              </Wallet>
            )}
          </div>

          {!userName && (
            <Button
              onClick={() => {
                onPresentAccountModal();
              }}
            >
              {accountEllipsis}
            </Button>
          )}
        </>
      ) : (
        <Button
          onClick={() => {
            onPresentConnectModal();
          }}
        >
          Connect
        </Button>
      )}
      <Menu
        style={menu && !!userName ? { display: "flex" } : { display: "none" }}
      >
        <MenuButton type="button">Account : {accountEllipsis}</MenuButton>
        <MenuButton
          type="button"
          onClick={(e) => {
            handleLogoutButtonClick(e, dispatch, reset);
            setMenu(false);
          }}
        >
          Logout
        </MenuButton>
      </Menu>
    </div>
  );
};

export default React.memo(
  UserBlock,
  (prevProps, nextProps) =>
    prevProps.account === nextProps.account &&
    prevProps.login === nextProps.login &&
    prevProps.logout === nextProps.logout
);
