import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useRefresh from "hooks/useRefresh";
import {
  fetchFarmsPublicDataAsync,
  fetchVotePublicDataAsync,
  fetchPoolsUserDataAsync,
} from "./actions";
import { State, Farm, Vote, Pool } from "./types";
import { QuoteToken } from "../config/constants/types";

const ZERO = new BigNumber(0);

export const useFetchPublicData = (webInstance) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync());

    if (webInstance) {
      dispatch(fetchVotePublicDataAsync(webInstance));
      const farmInterval = setInterval(() => {
        dispatch(fetchFarmsPublicDataAsync());
      }, 8000);
      const voteInterval = setInterval(() => {
        dispatch(fetchVotePublicDataAsync(webInstance));
      }, 8000);
      return () => {
        clearInterval(farmInterval);
        clearInterval(voteInterval);
      };
    }
    window?.addEventListener("storage", () => {
      if (webInstance) {
        dispatch(fetchVotePublicDataAsync(webInstance));
      }
      dispatch(fetchFarmsPublicDataAsync());
    });
    return null;
  });
};

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data);

  return farms;
};
export const useVotes = (): Vote[] => {
  const farms = useSelector((state: State) => state.votes.data);

  return farms;
};
export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.pid === pid)
  );
  return farm;
};

export const useVoteFromPid = (pid): Farm => {
  const farm = useSelector((state: State) =>
    state.votes.data.find((f) => f.pid === pid)
  );
  return farm;
};

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.lpSymbol === lpSymbol)
  );
  return farm;
};

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid);

  return {
    allowance: farm.userData
      ? new BigNumber(farm.userData.allowance)
      : new BigNumber(0),
    tokenBalance: farm.userData
      ? new BigNumber(farm.userData.tokenBalance)
      : new BigNumber(0),
    stakedBalance: farm.userData
      ? new BigNumber(farm.userData.stakedBalance)
      : new BigNumber(0),
    earnings: farm.userData
      ? new BigNumber(farm.userData.earnings)
      : new BigNumber(0),
  };
};

export const useVoteUser = (pid) => {
  const farm = useVoteFromPid(pid);

  return {
    allowance: farm.userData
      ? new BigNumber(farm.userData.allowance)
      : new BigNumber(0),
    tokenBalance: farm.userData
      ? new BigNumber(farm.userData.tokenBalance)
      : new BigNumber(0),
    stakedBalance: farm.userData
      ? new BigNumber(farm.userData.stakedBalance)
      : new BigNumber(0),
    earnings: farm.userData
      ? new BigNumber(farm.userData.earnings)
      : new BigNumber(0),
  };
};

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const pools = useSelector((state: State) => state.pools.data);
  return pools;
};

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) =>
    state.pools.data.find((p) => p.sousId === sousId)
  );
  return pool;
};

// detail

export const useVempDetails = () => {
  const detail = useSelector(
    (state: any) => state?.details?.vEmpireCardDetail?.vempDetails
  );

  return detail;
};

export const useShowUserName = () => {
  const detail = useSelector((state: any) => state?.details?.showUserName);

  return detail;
};
export const useTotalValueLocked = () => {
  const detail = useSelector(
    (state: any) => state?.details?.vEmpireCardDetail?.totalValueLocked
  );

  return detail;
};

export const useAPRDetails = () => {
  const detail = useSelector(
    (state: any) => state?.details?.vEmpireCardDetail?.APR
  );

  return detail;
};
export const useProvider = () => {
  const detail = useSelector((state: any) => state?.details?.provider);

  return detail;
};
export const useIsWebInstance = () => {
  const detail = useSelector((state: any) => state?.details?.isWebInstance);

  return detail;
};
export const useWebInstance = () => {
  const detail = useSelector((state: any) => state?.details?.webInstance);
  return detail;
};

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  return ZERO;
};

export const usePriceCakeBusd = (): BigNumber => {
  const pid = 0; // EGG-BUSD LP
  const farm = useFarmFromPid(pid);
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO;
};

export const useTotalValue = (): BigNumber => {
  const farms = useFarms();
  const bnbPrice = usePriceBnbBusd();
  const cakePrice = usePriceCakeBusd();
  let value = new BigNumber(0);
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i];
    if (farm.lpTotalInQuoteToken) {
      let val;
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(farm.lpTotalInQuoteToken);
      } else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = cakePrice.times(farm.lpTotalInQuoteToken);
      } else {
        val = farm.lpTotalInQuoteToken;
      }
      value = value.plus(val);
    }
  }
  return value;
};
