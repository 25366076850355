import {
  getUserInfo,
  getLpPairAmount,
  getBalanceVemp,
  getTotalLiquidity,
} from "utils/VoteHarvest";
import votesConfig from "config/constants/votes";
import { getChainId } from "utils/farmHarvest";

const fetchVote = async (webInstance) => {
  if (webInstance) {
    const data = await Promise.all(
      votesConfig.map(async (farms) => {
        const deposit = await getBalanceVemp(webInstance);
        const totalLiquidity = await getTotalLiquidity(webInstance);
        const userBalance = await getLpPairAmount(
          farms.lpAddresses,
          webInstance
        );
        const userStakedAmount = await getUserInfo(farms.pid, webInstance);
        const chainId = localStorage.getItem('chainId');

        return {
          ...farms,
          tokenBalance: userBalance,
          depositFeeBP: deposit,
          totalLiquidityAmount: totalLiquidity,
          stakedAmount: userStakedAmount,
          chainId,
        };
      })
    );
    return data;
  }
  return null;
};

export default fetchVote;
