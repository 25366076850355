import React, { useCallback, useMemo } from "react";
import BigNumber from "bignumber.js";
import TokenInput from "components/TokenInput";
import { getFullDisplayBalance } from "utils/formatBalance";
import { getBalanceOfxVempV2 } from "utils/VoteHarvest";
import { createWebInstance } from "utils/wallectConnectUtility";

interface WithdrawModalProps {
  max: BigNumber;
  onConfirm: (amount: string) => void;
  onDismiss?: () => void;
  tokenName?: string;
  farm?: any;
  setVal?: any;
  val?: string;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  farm,
  max,
  tokenName = "",
  val,
  setVal,
}) => {
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max);
  }, [max]);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );

  const handleSelectMax = useCallback(async() => {
    const webInstance = await createWebInstance();
    if (farm.pid > 0) {
      getBalanceOfxVempV2(farm.xVempV2Address,webInstance)
        .then((v) => setVal(v))
        .catch();
    } else {
      setVal((farm?.stakedAmount).toString());
    }
  }, [farm?.stakedAmount, setVal, farm.xVempV2Address, farm.pid]);

  return (
    <TokenInput
      userAccountBalance={farm.depositFeeBP}
      onSelectMax={handleSelectMax}
      onChange={handleChange}
      value={val}
      max={fullBalance}
      symbol={tokenName}
    />
  );
};

export default WithdrawModal;
