import React from "react";
import styled from "styled-components";
import { Flex, Button, useModal } from "@pancakeswap-libs/uikit";

export interface ExpandableSectionProps {
  lpLabel?: string;
  multiplier?: string;
  risk?: number;
  depositFee?: number;
  farmImage?: string;
  tokenSymbol?: string;
  pid?: number;
}

const Wrapper = styled(Flex)`
  background-color: black !important;
  svg {
    margin-right: 0.25rem;
  }
`;
const IBtn = styled(Button)`
  height: auto !important;
  padding: 0px !important;
  font-size: 15px !important;
  box-shadow: none;
`;

const CardHeading: React.FC<ExpandableSectionProps> = ({ lpLabel, pid }) => {
  const [handleInfo] = useModal(
    <div className="warningBorder">
      <div className="info-warning">
        <h1 className="infoHeading">Information</h1>
        <p
          className="infoParagraph"
          style={{ textTransform: "uppercase", fontSize: "17px" }}
        >
          {pid < 1 ? (
            <div>
              Rewards have now ended on the DDAO v1 pool. Please migrate to DDAO
              v2, or withdraw your VEMP from the contract, as you will no longer
              be able to interact with the pool after Feb 28th 2022
            </div>
          ) : (
            <div>
              WHEN YOU STAKE YOUR VEMP, YOU WILL QUALIFY TO BE GIFTED A SHARE OF
              PROFITS ACROSS ALL VARIOUS METAVERSE POOLS AND GAMES. THIS GRANTS
              YOU EXPOSURE TO OVER TEN METAVERSES AS WELL AS NFT PROTOCOLS.
              DEPOSIT YOUR VEMP INTO THE DDAO CONTRACT TO BE GIFTED PROFITS.
              ANOTHER 9%-19% OF PROFITS GETS USED FOR BUY-BACK AND BURNS TO
              DECREASE THE OVERALL SUPPLY OF VEMP. AFTER DEPOSITING VEMP, YOU
              WILL RECEIVE XVEMP YOU CAN EXCHANGE YOUR XVEMP TO REDEEM YOUR
              INITIAL DEPOSIT, PLUS THE VEMP YOU EARN OVER TIME
            </div>
          )}
        </p>
      </div>
    </div>
  );
  return (
    <Wrapper alignSelf="center" mb="18px">
      <h1 style={{ fontWeight: 600, fontSize: "24px" }}>{lpLabel}</h1>
      <div style={{ position: "absolute", right: "12px", top: "36px" }}>
        <IBtn onClick={handleInfo}>
          <span>&#9432;</span>
        </IBtn>
      </div>
    </Wrapper>
  );
};

export default CardHeading;
