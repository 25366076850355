/* eslint-disable import/no-cycle */

import Web3 from "web3";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { bnbNetworkId, ethNetworkId, PINATA_BASE_URI } from "config";
import cheffAbi from "../config/abi/masterchef.json";
import erc20 from "../config/abi/manaToken.json";
import * as Addresses from "../config/constants/addresses";
import starLAbi from "../config/abi/starLAbi.json";
import sandAbi from "../config/abi/sandAbi.json";
import ethABI from "../config/abi/ethAbi.json";
import apeStakingABI from "../config/abi/apeStakingABI.json";
import usdcTokenABI from "../config/abi/usdcTokenABI.json";
import cheffManaAbi from "../config/abi/cheffMana.json";
import {
  adminUpgradabilityAddress,
  plebAddress,
  plebStakingAddress,
  soldierAddress,
  soldierStakingAddress,
  VempAddress,
} from "../config/constants/addresses";
import bnbPoolABI from "../config/abi/bnbPoolABI.json";
import plebABI from "../config/abi/plebNFT.json";
import plebStakingABI from "../config/abi/plebStaking.json";
import adminUpgradabilty from "../config/abi/adminUpgradabilityAbi.json";
import adminUpgradabiltyStartLV2 from "../config/abi/version2ABI/adminUpgradabilityStarLV2.json";
import mockTokenABi from "../config/abi/mockTokenAbi.json";
import soldierABI from "../config/abi/soldierNFT.json";
import soldierStakingABI from "../config/abi/soldierStaking.json";
import { getMasterChefAddressMana } from "./addressHelpers";
import { evaluateChain } from "./VoteHarvest";

declare const window: any;

const cheffAddress = getMasterChefAddressMana();

export const fetchAccounts = () => {
  return new Promise((resolve) => {
    const ethAccounts = getAccounts();
    resolve(ethAccounts);
  });
};
export const isCorrectChain = async (value) => {
  try {
    const chainID = localStorage.getItem("chainId");
    return String(value) === String(chainID);
  } catch (e) {
    return "";
  }
};

export const getChainId = async () => {
  try {
    const web3 = new Web3(window?.ethereum);
    const chainId = await web3?.eth?.getChainId();
    storeCorrectChain(chainId);
    return chainId;
  } catch (e) {
    return "";
  }
};

export const getAccounts = async () => {
  try {
    const web3 = new Web3(window?.ethereum);
    const wallet = await web3?.eth?.getAccounts();
    return wallet;
  } catch (e) {
    return "";
  }
};
export const connectOkx = async (
  handleClick,
  onDismiss,
  walletLocalStorageKey,
  connectorLocalStorageKey,
  walletConfig
) => {
  const okxWallet = window?.okxwallet;
  if (okxWallet) {
    const web3 = new Web3(window?.okxwallet);
    const chain = await web3?.eth?.getChainId();
    storeCorrectChain(chain);
    okxWallet?.on("accountsChanged", (accounts: string[]) => {
      if (accounts[0]) {
        localStorage.setItem("account", accounts[0]);
      }
    });
    okxWallet?.on("chainChanged", (chainId) => {
      if (chainId.includes("38") || chainId.includes("61")) {
        storeCorrectChain(bnbNetworkId);
      } else {
        storeCorrectChain(chainId);
      }
      window?.dispatchEvent(new Event("storage"));
    });
    const account = await okxWallet?.request({ method: "eth_requestAccounts" });
    if (account?.length > 0) {
      localStorage.setItem("account", account);
      localStorage.setItem(walletLocalStorageKey, walletConfig.title);
      localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
    }
  } else {
    handleClick("Please install OKX Wallet extension");
  }
  onDismiss();
};
export const openMetamask = async () => {
  const addresses = await getAccounts();
  return addresses.length ? addresses[0] : null;
};

// Get user Viking balance

export const getBalanceOf = async (lpAddress, webInstance: any) => {
  try {
    const totalBalance = await webInstance.eth.getBalance(
      lpAddress,
      (err: any) => {
        if (err) {
          // console.log(err);
        }
      }
    );
    const totalUserBalance = totalBalance / 10 ** 18;
    return totalUserBalance || 0;
  } catch (e) {
    return 0.0;
  }
};

export const getUserLockInfo = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "0";
  }
  let lockResponse;
  try {
    if (!farm.isTokenOnly) {
      const contract = new webInstance.eth.Contract(
        adminUpgradabilty,
        adminUpgradabilityAddress
      );
      if (farm.lpSymbol === "STARLINK") {
        lockResponse = await contract.methods
          .userLockInfo(Addresses.starLink, account)
          .call();
      } else if (farm.lpSymbol === "MANA") {
        lockResponse = await contract.methods
          .userLockInfo(Addresses.masterChefMana, account)
          .call();
      } else if (farm.lpSymbol === "SAND") {
        lockResponse = await contract.methods
          .userLockInfo(Addresses.masterChefSand, account)
          .call();
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        lockResponse = await contract.methods.userLockInfo(account).call();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        lockResponse = await contract.methods.userLockInfo(account).call();
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        lockResponse = await contract.methods.userLockInfo(account).call();
      }
    }
    return lockResponse;
  } catch (error) {
    return 0.0;
  }
};

export const getUserLockPeriod = async (webInstance: any) => {
  try {
    const contract = new webInstance.eth.Contract(
      adminUpgradabilty,
      adminUpgradabilityAddress
    );
    const lockResponse = await contract.methods.lockPeriod().call();
    return lockResponse;
  } catch (error) {
    return 0.0;
  }
};

export const getWithdrawInfo = async (farm, stakedAmount, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return { withdrawRes: false, lockResponse: "0" };
  }
  let lockResponse;
  let withdrawRes;
  try {
    if (farm.lpSymbol === "STARLINK") {
      const contract = new webInstance.eth.Contract(
        adminUpgradabilty,
        adminUpgradabilityAddress
      );
      lockResponse = await contract.methods
        .withdrawInfo(Addresses.starLink, account)
        .call();

      if (Number(stakedAmount) === Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = true;
      }
      if (Number(stakedAmount) !== Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = false;
      }
    }
    if (farm.lpSymbol === "MANA") {
      const contract = new webInstance.eth.Contract(
        adminUpgradabilty,
        Addresses.liquidityPoolAddress
      );
      lockResponse = await contract.methods
        .withdrawInfo(Addresses.masterChefMana, account)
        .call();

      if (Number(stakedAmount) === Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = true;
      }
      if (Number(stakedAmount) !== Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = false;
      }
    }
    if (farm.lpSymbol === "SAND") {
      const contract = new webInstance.eth.Contract(
        adminUpgradabilty,
        Addresses.liquidityPoolAddress
      );
      lockResponse = await contract.methods
        .withdrawInfo(Addresses.masterChefSand, account)
        .call();

      if (Number(stakedAmount) === Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = true;
      }
      if (Number(stakedAmount) !== Number(lockResponse?.amount / 10 ** 18)) {
        withdrawRes = false;
      }
    }
    lockResponse = new BigNumber(lockResponse?.amount)
      .div(new BigNumber(10).pow(18))
      .toString();

    return { withdrawRes, lockResponse };
  } catch (error) {
    return 0.0;
  }
};

export const getLockBalanceOf = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(
      mockTokenABi,
      "0x9fc889ff92cbeeb12f8b70be8f0e7f329f12d2b5" // mainnet
    );
    const balanceResponse = await contract.methods.balanceOf(account).call();

    return balanceResponse;
  } catch (error) {
    return 0.0;
  }
};

export const mockApprove = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    let cheffResponse;
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();

    if (!farm.isTokenOnly) {
      const contract = new ethers.Contract(
        farm.lpSymbol === "MANA" || farm.lpSymbol === "SAND"
          ? Addresses.VempAddress
          : Addresses.mockTokenAddress,
        mockTokenABi,
        provider
      );
      cheffResponse = await contract.approve(
        farm.lpSymbol === "MANA" || farm.lpSymbol === "SAND"
          ? Addresses.liquidityPoolAddress
          : adminUpgradabilityAddress,
        ethers.constants.MaxUint256
      );
    } else {
      const contract = new ethers.Contract(
        farm.lpSymbol === "MANA" || farm.lpSymbol === "SAND"
          ? Addresses.VempAddress
          : Addresses.mockTokenAddress,
        mockTokenABi,
        provider
      );
      cheffResponse = await contract.approve(
        farm.cheffAddress,
        ethers.constants.MaxUint256
      );
    }
    return cheffResponse;
  } catch (error) {
    return error;
  }
};

export const mockAllowances = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let allowanceResponse: any;
  try {
    if (!farm.isTokenOnly) {
      const contract = new webInstance.eth.Contract(
        mockTokenABi,
        Addresses.VempAddress
      );
      allowanceResponse = await contract.methods
        .allowance(account, Addresses.liquidityPoolAddress)
        .call();
    } else {
      const contract = new webInstance.eth.Contract(
        mockTokenABi,
        Addresses.VempAddress
      );

      allowanceResponse = await contract.methods
        .allowance(account, farm.cheffAddress)
        .call();
    }
    return allowanceResponse;
  } catch (error) {
    return 0.0;
  }
};

export const lockToken = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let lockResponse;
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    if (!farm.isTokenOnly) {
      const contract = new ethers.Contract(
        Addresses.liquidityPoolAddress,
        adminUpgradabilty,
        provider
      );

      if (farm.lpSymbol === "STARLINK") {
        lockResponse = await contract.lock(Addresses.starLink);
      } else if (farm.lpSymbol === "MANA") {
        lockResponse = await contract.lock(Addresses.masterChefMana);
      } else if (farm.lpSymbol === "SAND") {
        lockResponse = await contract.lock(Addresses.masterChefSand);
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          Addresses.adminUpgradabilityStarLV2Address,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.lock();
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.manaPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.lock();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.sandPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.lock();
      }
    }

    return lockResponse;
  } catch (error) {
    return error;
  }
};

export const getUnstaked = async (
  farm,
  unstake,
  migrate,
  amount,
  webInstance: any
) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    let lockResponse;
    const reqAmount = new BigNumber(amount)
      .times(new BigNumber(10).pow(18))
      .toString();
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    if (!farm.isTokenOnly) {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          adminUpgradabilityAddress,
          adminUpgradabilty,
          provider
        );
        lockResponse = await contract.unstake(
          Addresses.starLink,
          true,
          migrate
        );
      } else if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.liquidityPoolAddress,
          adminUpgradabilty,
          provider
        );
        lockResponse = await contract.unstake(
          Addresses.masterChefSand,
          true,
          migrate
        );
      } else if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.liquidityPoolAddress,
          adminUpgradabilty,
          provider
        );
        lockResponse = await contract.unstake(
          Addresses.masterChefMana,
          true,
          migrate
        );
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          Addresses.adminUpgradabilityStarLV2Address,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.withdraw(reqAmount, unstake);
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.sandPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.withdraw(reqAmount, unstake);
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.manaPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        lockResponse = await contract.withdraw(reqAmount, unstake);
      }
    }
    return lockResponse;
  } catch (error) {
    return error;
  }
};

export const getVempLockPercentage = async (webInstance: any) => {
  try {
    const contract = new webInstance.eth.Contract(
      adminUpgradabilty,
      adminUpgradabilityAddress
    );
    const lockResponse: any = await contract.methods.vempLockPercent().call();

    return lockResponse;
  } catch (error) {
    return 0.0;
  }
};

export const getStarlinkBalance = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "0";
  }
  let lpBalanceOf: any;
  try {
    if (farm.lpSymbol === "STARLINK") {
      const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
      lpBalanceOf = await contract.methods
        .balanceOf(adminUpgradabilityAddress)
        .call();
    } else if (farm.lpSymbol === "MANA") {
      const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
      lpBalanceOf = await contract.methods
        .balanceOf(Addresses.liquidityPoolAddress)
        .call();
    } else if (farm.lpSymbol === "SAND") {
      const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
      lpBalanceOf = await contract.methods
        .balanceOf(Addresses.liquidityPoolAddress)
        .call();
    }

    return lpBalanceOf;
  } catch (error) {
    return 0;
  }
};

export const getVempLockAmount = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return false;
  }
  const vempBalance = await getVempBalance(webInstance);
  let lockAmountResponse: any;
  try {
    if (!farm.isTokenOnly) {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabilty,
          adminUpgradabilityAddress
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      } else if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabilty,
          adminUpgradabilityAddress
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      } else if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabilty,
          adminUpgradabilityAddress
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      }

      if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        lockAmountResponse = await contract.methods.vempLockAmount().call();
      }
    }
    lockAmountResponse /= 10 ** 18;
    return Number(lockAmountResponse) > Number(vempBalance / 10 ** 18);
  } catch (error) {
    return false;
  }
};

export const getVempBalance = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(erc20, VempAddress);
    const lpBalanceOf = await contract.methods.balanceOf(account).call();
    return lpBalanceOf;
  } catch (error) {
    return 0;
  }
};

export const getLpPairAmount = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    if (farm.lpAddresses) {
      const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
      let lpPairResponse = await contract.methods.balanceOf(account).call();
      lpPairResponse =
        webInstance?.utils?.fromWei(lpPairResponse, "ether") || 0;

      return lpPairResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Get BNB balance

export const getBnbBalanceOf = async (webInstance: any) => {
  try {
    const account = await checkConnectedAndGetAddress();
    const totalBalance = await webInstance?.eth?.getBalance(
      account,
      (err: any) => {
        if (err) {
          // console.log(err);
        }
      }
    );
    const totalUserBalance = webInstance?.utils?.fromWei(totalBalance, "ether");
    return totalUserBalance || "0";
  } catch (e) {
    return "0";
  }
};

// Function to get userAddress

export const checkConnectedAndGetAddress = async () => {
  return localStorage.getItem("account");
};
// Function to call donate function

export const stake = async (
  farm,
  tokenCheffAddress,
  amount,
  webInstance: any
) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    const tokenABI = getTokenAbi(farm.lpSymbol);
    let cheffResponse;
    const reqAmount = new BigNumber(amount)
      .times(new BigNumber(10).pow(18))
      .toString();
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    if (!farm.isTokenOnly) {
      if (tokenCheffAddress) {
        const contract = new ethers.Contract(
          tokenCheffAddress,
          tokenABI,
          provider
        );

        if (farm.lpSymbol === "ETH" || farm.lpSymbol === "BNB") {
          const val = ethers.utils.parseEther(amount);
          cheffResponse = await contract.deposit(val, { value: val });
        } else if (
          farm.lpSymbol === "APE" ||
          farm.lpSymbol === "USDC" ||
          farm.lpSymbol === "BUSD"
        ) {
          cheffResponse = await contract.deposit(reqAmount);
        } else {
          cheffResponse = await contract.deposit(0, reqAmount);
        }

        return cheffResponse;
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          Addresses.adminUpgradabilityStarLV2Address,
          adminUpgradabiltyStartLV2,
          provider
        );

        cheffResponse = await contract.deposit(account, reqAmount);
      } else if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.manaPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        cheffResponse = await contract.deposit(account, reqAmount);
      } else if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.sandPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        cheffResponse = await contract.deposit(account, reqAmount);
      }
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};
export const claimBalance = async (
  farm,
  tokenCheffAddress,
  amount,
  webInstance: any
) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    const tokenABI = getTokenAbi(farm.lpSymbol);
    let cheffResponse;
    const reqAmount = new BigNumber(amount)
      .times(new BigNumber(10).pow(18))
      .toString();
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    if (!farm.isTokenOnly) {
      if (tokenCheffAddress) {
        const contract = new ethers.Contract(
          tokenCheffAddress,
          tokenABI,
          provider
        );

        if (farm.lpSymbol === "ETH" || farm.lpSymbol === "BNB") {
          // const val = ethers.utils.parseEther(amount);
          cheffResponse = await contract.deposit(reqAmount);
        } else if (
          farm.lpSymbol === "APE" ||
          farm.lpSymbol === "USDC" ||
          farm.lpSymbol === "BUSD"
        ) {
          cheffResponse = await contract.deposit(reqAmount);
        } else {
          cheffResponse = await contract.deposit(0, reqAmount);
        }

        return cheffResponse;
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          Addresses.adminUpgradabilityStarLV2Address,
          adminUpgradabiltyStartLV2,
          provider
        );

        cheffResponse = await contract.deposit(account, reqAmount);
      } else if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.manaPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        cheffResponse = await contract.deposit(account, reqAmount);
      } else if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.sandPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        cheffResponse = await contract.deposit(account, reqAmount);
      }
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const approve = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let cheffResponse;
  try {
    if (farm.lpAddresses) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      if (!farm.isTokenOnly) {
        const contract = new ethers.Contract(farm.lpAddresses, erc20, provider);
        cheffResponse = await contract.approve(
          farm.cheffAddress,
          ethers.constants.MaxUint256
        );
      } else {
        const contract = new ethers.Contract(farm.lpAddresses, erc20, provider);
        cheffResponse = await contract.approve(
          farm.cheffAddress,
          ethers.constants.MaxUint256
        );
      }
      return cheffResponse;
    }
    return cheffResponse;
  } catch (error) {
    return error;
  }
};

// Function to call withdraw amount function

export const withdraw = async (amount, webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();
      const contract = new webInstance.eth.Contract(cheffAbi, cheffAddress);
      const reqAmount = new BigNumber(amount)
        .times(new BigNumber(10).pow(18))
        .toString();

      const cheffResponse = await contract.methods
        .withdraw(0, reqAmount)
        .send({ from: account });
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return "";
  }
};

// Function to get staked amount

export const getUserInfo = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "0.00";
  }
  const tokenABI = getTokenAbi(farm.lpSymbol);
  let cheffResponse;
  try {
    if (!farm?.isTokenOnly) {
      if (farm?.cheffAddress) {
        const contract = new webInstance.eth.Contract(
          tokenABI,
          farm?.cheffAddress
        );
        if (
          farm?.lpSymbol === "ETH" ||
          farm?.lpSymbol === "BNB" ||
          farm?.lpSymbol === "APE" ||
          farm?.lpSymbol === "USDC" ||
          farm?.lpSymbol === "BUSD"
        ) {
          cheffResponse = await contract?.methods?.userInfo(account).call();
        } else {
          cheffResponse = await contract.methods.userInfo(0, account).call();
        }
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        cheffResponse = await contract.methods.userInfo(account).call();
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        cheffResponse = await contract.methods.userInfo(account).call();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        cheffResponse = await contract.methods.userInfo(account).call();
      }
    }
    const reqResult = new BigNumber(cheffResponse.amount)
      .div(new BigNumber(10).pow(18))
      .toString();
    return reqResult;
  } catch (error) {
    return "0.00";
  }
};

export const getWithdrawStatus = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  try {
    let statusResponse: any;
    if (farm.isTokenOnly) {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        statusResponse = await contract.methods.withdrawStatus().call();
      } else if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        statusResponse = await contract.methods.withdrawStatus().call();
      } else if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        statusResponse = await contract.methods.withdrawStatus().call();
      }
    }
    return statusResponse;
  } catch (error) {
    // console.log(error);
  }
  return 0;
};

export const unStake = async (
  tokenCheffAddress,
  lpSymbol,
  amount,
  webInstance: any
) => {
  const tokenABi = getTokenAbi(lpSymbol);
  const reqAmount = new BigNumber(amount)
    .times(new BigNumber(10).pow(18))
    .toString();
  let cheffResponse;
  try {
    if (cheffAddress) {
      const provider = new ethers.providers.Web3Provider(
        webInstance
      ).getSigner();
      const contract = new ethers.Contract(
        tokenCheffAddress,
        tokenABi,
        provider
      );
      cheffResponse = await contract.withdraw(reqAmount);
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const unstakeStatus = async (
  tokenCheffAddress,
  lpSymbol,
  webInstance: any
) => {
  const tokenABi = getTokenAbi(lpSymbol);

  let cheffResponse;
  try {
    if (cheffAddress) {
      const contract = new webInstance.eth.Contract(
        tokenABi,
        tokenCheffAddress
      );
      cheffResponse = await contract.methods.withdrawStatus().call();
      return cheffResponse;
    }
    return "";
  } catch (error) {
    return "0.00";
  }
};

export const getAllowances = async (farm, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let allowanceResponse;
  try {
    if (farm.lpAddresses) {
      if (!farm.isTokenOnly) {
        const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
        allowanceResponse = await contract.methods
          .allowance(account, farm.cheffAddress)
          .call();
      } else {
        const contract = new webInstance.eth.Contract(erc20, farm.lpAddresses);
        allowanceResponse = await contract.methods
          .allowance(account, farm.cheffAddress)
          .call();
      }

      return allowanceResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getPendingVEMP = async (
  farm,
  tokenCheffAddress,
  lpSymbol,
  webInstance: any
) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  const tokenABi = getTokenAbi(lpSymbol);
  let pendingEggsResponse;
  try {
    if (!farm.isTokenOnly) {
      if (tokenCheffAddress) {
        const contract = new webInstance.eth.Contract(
          tokenABi,
          tokenCheffAddress
        );
        if (
          lpSymbol === "ETH" ||
          lpSymbol === "BNB" ||
          lpSymbol === "APE" ||
          lpSymbol === "USDC" ||
          lpSymbol === "BUSD"
        ) {
          pendingEggsResponse = await contract.methods
            .pendingVEMP(account)
            .call();
        } else {
          pendingEggsResponse = await contract.methods
            .pendingVEMP(0, account)
            .call();
        }

        pendingEggsResponse = pendingEggsResponse / 10 ** 18 || 0;
        return pendingEggsResponse;
      }
    } else {
      if (lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        pendingEggsResponse = await contract.methods
          .pendingVEMP(account)
          .call();
      }
      if (lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        pendingEggsResponse = await contract.methods
          .pendingVEMP(account)
          .call();
      }
      if (lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        pendingEggsResponse = await contract.methods
          .pendingVEMP(account)
          .call();
      }
      pendingEggsResponse = pendingEggsResponse / 10 ** 18 || 0;
      return pendingEggsResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// to get multiplier value

export const getPoolInfo = async (
  farm,
  tokenCheffAddress,
  webInstance: any
) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let poolInfo;
  const tokenABi = getTokenAbi(farm.lpSymbol);
  try {
    if (!farm.isTokenOnly) {
      if (tokenCheffAddress) {
        const contract = new webInstance.eth.Contract(
          tokenABi,
          tokenCheffAddress
        );
        if (farm.lpSymbol === "ETH" || farm.lpSymbol === "BNB") {
          poolInfo = await contract.methods.poolInfo().call();
        } else {
          poolInfo = await contract.methods.poolInfo(0).call();
        }

        poolInfo = poolInfo.allocPoint / 100;

        return poolInfo;
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        poolInfo = await contract.methods.poolInfo().call();
      }
      poolInfo = poolInfo?.allocPoint / 100;
      return poolInfo;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getDepositFees = async (pid, webInstance: any) => {
  try {
    if (cheffAddress) {
      const account = await checkConnectedAndGetAddress();
      const contract = new webInstance.eth.Contract(cheffAbi, cheffAddress);
      const depositFee = await contract.methods.userInfo(0, account).call();

      return depositFee.amount;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const vempPerBlock = async (webInstance: any) => {
  try {
    if (cheffAddress) {
      const contract = new webInstance.eth.Contract(cheffAbi, cheffAddress);
      const vempResponse = await contract.methods.VEMPPerBlock().call();

      return vempResponse / 10 ** 18;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const vempPerBlockofBNB = async (chfAddress, webInstance: any) => {
  try {
    if (cheffAddress) {
      const contract = new webInstance.eth.Contract(cheffAbi, chfAddress);
      const vempResponse = await contract.methods.VEMPPerBlock().call();

      return vempResponse / 10 ** 18;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

export const getPendingToken = async (farm, ABI, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "0.00";
  }
  let pendingResponse;
  try {
    if (!farm.isTokenOnly) {
      if (farm.cheffAddress) {
        const contract = new webInstance.eth.Contract(ABI, farm.cheffAddress);
        if (farm.lpSymbol === "STARLINK") {
          pendingResponse = await contract.methods
            .pendingSTARL(0, account)
            .call();
        } else if (farm.lpSymbol === "MANA") {
          pendingResponse = await contract.methods
            .pendingMANA(0, account)
            .call();
        } else if (farm.lpSymbol === "SAND") {
          pendingResponse = await contract.methods
            .pendingSAND(0, account)
            .call();
        } else if (farm.lpSymbol === "ETH") {
          pendingResponse = await contract.methods.pendingETH(account).call();
        } else if (farm.lpSymbol === "BNB") {
          pendingResponse = await contract.methods.pendingBNB(account).call();
        } else if (farm.lpSymbol === "APE") {
          pendingResponse = await contract.methods.pendingAPE(account).call();
        } else if (farm.lpSymbol === "USDC") {
          pendingResponse = await contract.methods.pendingUSDC(account).call();
        } else if (farm.lpSymbol === "BUSD") {
          pendingResponse = await contract.methods.pendingUSDC(account).call();
        }
      }
      pendingResponse = new BigNumber(pendingResponse)
        .div(new BigNumber(10).pow(18))
        .toString();
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.adminUpgradabilityStarLV2Address
        );
        pendingResponse = await contract.methods.pendingLP(account).call();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.sandPoolAddress
        );
        pendingResponse = await contract.methods.pendingLP(account).call();
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new webInstance.eth.Contract(
          adminUpgradabiltyStartLV2,
          Addresses.manaPoolAddress
        );
        pendingResponse = await contract.methods.pendingLP(account).call();
      }
      pendingResponse = new BigNumber(pendingResponse)
        .div(new BigNumber(10).pow(18))
        .toString();
    }
    return pendingResponse;
  } catch (error) {
    return "0.00";
  }
};

export const getClaimToken = async (farm, ABI, Address, webInstance: any) => {
  const correctChain = await isCorrectChain(farm?.network);
  const account = await checkConnectedAndGetAddress();
  if (!correctChain || !account) {
    return "";
  }
  let claimResponse;
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    if (!farm.isTokenOnly) {
      if (Address) {
        const contract = new ethers.Contract(Address, ABI, provider);
        if (farm.lpSymbol === "STARL") {
          claimResponse = await contract.claimSTARL(0);
        } else if (farm.lpSymbol === "MANA") {
          claimResponse = await contract.claimMANA(0);
        } else if (farm.lpSymbol === "SAND") {
          claimResponse = await contract.claimSAND(0);
        } else if (farm.lpSymbol === "ETH") {
          claimResponse = await contract.claimETH();
        } else if (farm.lpSymbol === "BNB") {
          claimResponse = await contract.claimBNB();
        } else if (farm.lpSymbol === "APE") {
          claimResponse = await contract.claimAPE();
        } else if (farm.lpSymbol === "USDC") {
          claimResponse = await contract.claimUSDC();
        } else if (farm.lpSymbol === "BUSD") {
          claimResponse = await contract.claimUSDC();
        }

        return claimResponse;
      }
    } else {
      if (farm.lpSymbol === "STARLINK") {
        const contract = new ethers.Contract(
          Addresses.adminUpgradabilityStarLV2Address,
          adminUpgradabiltyStartLV2,
          provider
        );
        claimResponse = await contract.claimLP();
      }
      if (farm.lpSymbol === "MANA") {
        const contract = new ethers.Contract(
          Addresses.manaPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        claimResponse = await contract.claimLP();
      }
      if (farm.lpSymbol === "SAND") {
        const contract = new ethers.Contract(
          Addresses.sandPoolAddress,
          adminUpgradabiltyStartLV2,
          provider
        );
        claimResponse = await contract.claimLP();
      }

      return claimResponse;
    }
    return "";
  } catch (error) {
    return error;
  }
};

export const getTokenAbi = (lpSymbol) => {
  switch (lpSymbol) {
    case "MANA": {
      return cheffManaAbi;
    }
    case "STARLINK": {
      return starLAbi;
    }
    case "SAND": {
      return sandAbi;
    }
    case "ETH": {
      return ethABI;
    }
    case "BNB": {
      return bnbPoolABI;
    }
    case "APE": {
      return apeStakingABI;
    }
    case "USDC": {
      return usdcTokenABI;
    }
    case "BUSD": {
      return usdcTokenABI;
    }
    default:
      return "";
  }
};

export const getVempTokenPrice = async () => {
  try {
    const tokenResponse = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=vEmpire-DDAO&vs_currencies=USD"
    );
    let tokenPrice = await tokenResponse.json();
    tokenPrice = tokenPrice["vempire-ddao"].usd;
    localStorage.setItem("vempPrice", tokenPrice);
    return tokenPrice;
  } catch {
    return "";
  }
};

export const getAllDetails = async () => {
  try {
    const apiResponse = await fetch(
      "https://api.v-empire.io/static/statistics.json"
    );
    const apiDetails = await apiResponse.json();
    return apiDetails;
  } catch (error) {
    return "";
  }
};

export const getTokenPrice = async (lpSymbol) => {
  try {
    switch (lpSymbol) {
      case "MANA": {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=Decentraland&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();
        tokenPrice = tokenPrice.decentraland.usd;
        return tokenPrice;
      }
      case "STARLINK": {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=StarLink&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();
        tokenPrice = tokenPrice.starlink.usd;
        return tokenPrice;
      }
      case "SAND": {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=The-Sandbox&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();
        tokenPrice = tokenPrice["the-sandbox"].usd;

        return tokenPrice;
      }
      case "ETH": {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();

        tokenPrice = tokenPrice.ethereum.usd;
        return tokenPrice;
      }
      case "BNB": {
        const tokenResponse = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=USD"
        );
        let tokenPrice = await tokenResponse.json();

        tokenPrice = tokenPrice.binancecoin.usd;
        return tokenPrice;
      }
      default:
        return "";
    }
  } catch (error) {
    return "";
  }
};

export const totalSupply = async (webInstance: any) => {
  try {
    if (Addresses.VempAddress) {
      const contract = new webInstance.eth.Contract(
        erc20,
        Addresses.VempAddress
      );
      let pendingResponse = await contract.methods.totalSupply().call();
      pendingResponse = (pendingResponse / 10 ** 18).toFixed(2) || 0;
      return pendingResponse;
    }
    return "";
  } catch (error) {
    return 0.0;
  }
};

// Pleb NFT

export const plebBalance = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(plebABI, plebAddress);
    const balance = await contract.methods.balanceOf(account).call();

    return balance;
  } catch (error) {
    return 0.0;
  }
};

export const plebTokenArray = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(plebABI, plebAddress);
    const balance = await contract.methods.balanceOf(account).call();
    const plebArray = [];
    for (let i = 0; i < parseInt(balance); i++) {
      /* eslint-disable no-await-in-loop */
      const pleb = await contract.methods
        .tokenOfOwnerByIndex(account, i)
        .call();
      plebArray.push(pleb);
    }

    return plebArray;
  } catch (error) {
    return 0.0;
  }
};

export const plebApprove = async (webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(plebAddress, plebABI, provider);
    const approv = await contract.setApprovalForAll(plebStakingAddress, true);

    return approv;
  } catch (error) {
    return error;
  }
};

export const plebAllowance = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(plebABI, plebAddress);
    const allowance = await contract.methods
      .isApprovedForAll(account, plebStakingAddress)
      .call();
    return allowance;
  } catch (error) {
    return 0.0;
  }
};

// pleb NFT staking

export const stakePlebToken = async (id, webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(
      plebStakingAddress,
      plebStakingABI,
      provider
    );
    const staked = await contract.deposit(id);

    return staked;
  } catch (error) {
    return error;
  }
};

export const plebStaked = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(
      plebStakingABI,
      plebStakingAddress
    );
    const totalDeposited = await contract.methods.depositsOf(account).call();

    return totalDeposited;
  } catch (error) {
    return 0.0;
  }
};

export const claimablePleb = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(
      plebStakingABI,
      plebStakingAddress
    );
    const staked = await contract.methods.claimableRewards(account).call();

    return staked / 10 ** 18;
  } catch (error) {
    return 0.0;
  }
};

export const claimPleb = async (webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(
      plebStakingAddress,
      plebStakingABI,
      provider
    );
    const claimed = await contract.claimRewards();

    return claimed;
  } catch (error) {
    return error;
  }
};

export const withdrawPlebToken = async (id, webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(
      plebStakingAddress,
      plebStakingABI,
      provider
    );
    const unstaked = await contract.withdraw(id);

    return unstaked;
  } catch (error) {
    return error;
  }
};

export const baseUri = async (webInstance: any) => {
  try {
    if (PINATA_BASE_URI) {
      return PINATA_BASE_URI;
    }
    const contract = new webInstance.eth.Contract(plebABI, plebAddress);
    const uri = await contract.methods.baseURI().call();
    return uri;
  } catch (error) {
    return error;
  }
};

// soldier NFT

export const soldierBalance = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(soldierABI, soldierAddress);
    const balance = await contract.methods.balanceOf(account).call();

    return balance;
  } catch (error) {
    return 0.0;
  }
};

export const soldierApprove = async (webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(soldierAddress, soldierABI, provider);
    const approv = await contract.setApprovalForAll(
      soldierStakingAddress,
      true
    );

    return approv;
  } catch (error) {
    return error;
  }
};

export const soldierAllowance = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(soldierABI, soldierAddress);
    const allowance = await contract.methods
      .isApprovedForAll(account, soldierStakingAddress)
      .call();
    return allowance;
  } catch (error) {
    return 0.0;
  }
};

// soldier NFT staking

export const stakeSoldierToken = async (id, webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();

    const arr = [];
    for (let i = 1; i <= id.length; i++) {
      arr.push(1);
    }
    const contract = new ethers.Contract(
      soldierStakingAddress,
      soldierStakingABI,
      provider
    );
    const staked = await contract.deposit(id, arr);

    return staked;
  } catch (error) {
    return error;
  }
};

export const soldierStaked = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(
      soldierStakingABI,
      soldierStakingAddress
    );
    const totalDeposited = await contract.methods.depositsOf(account).call();

    return totalDeposited;
  } catch (error) {
    return 0.0;
  }
};

export const claimableSoldier = async (webInstance: any) => {
  const account = await checkConnectedAndGetAddress();
  try {
    const contract = new webInstance.eth.Contract(
      soldierStakingABI,
      soldierStakingAddress
    );
    const staked = await contract.methods.claimableRewards(account).call();

    return staked / 10 ** 18;
  } catch (error) {
    return 0.0;
  }
};

export const claimSoldier = async (webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();
    const contract = new ethers.Contract(
      soldierStakingAddress,
      soldierStakingABI,
      provider
    );
    const claimed = await contract.claimRewards();

    return claimed;
  } catch (error) {
    return error;
  }
};

export const withdrawSoldierToken = async (id, webInstance: any) => {
  try {
    const provider = new ethers.providers.Web3Provider(webInstance).getSigner();

    const contract = new ethers.Contract(
      soldierStakingAddress,
      soldierStakingABI,
      provider
    );
    const arr = [];
    for (let i = 1; i <= id.length; i++) {
      arr.push(1);
    }
    const unstaked = await contract.withdraw(id, arr);

    return unstaked;
  } catch (error) {
    return error;
  }
};

export const showMessage = (result, callback) => {
  const errorObj = String(result);
  if (result?.hash || result?.transactionHash) {
    callback("Transaction completed successfully!");
  } else if (
    result?.code === 4001 ||
    errorObj.includes("User rejected the transaction")
  ) {
    callback("Transaction rejected!");
  } else {
    callback("Transaction failed!");
  }
};

export const storeCorrectChain = (chain) => {
  const chainId = evaluateChain(chain);
  const network = [ethNetworkId, bnbNetworkId].includes(String(chainId));
  localStorage.setItem("correctNetwork", `${network}`);
  localStorage.setItem("chainId", evaluateChain(chainId));
};
export const isBinanceChain = (chain) => {
  const isbinance = ["38", "61"].includes(String(chain));
  return isbinance;
};
