import React from "react";
import styled from "styled-components";
import PanelBody from "./PanelBody";
import { SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from "../Menu/config";
import { PanelProps, PushedProps } from "../Menu/types";

interface Props extends PanelProps, PushedProps {
  showMenu: boolean;
  isMobile: boolean;
}

const StyledPanel = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  position: fixed;
  /* padding-top: ${({ showMenu }) => (showMenu ? "80px" : 0)}; */
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  /* background-color: ${({ theme }) => theme.nav.background}; */
  /* background: radial-gradient(
    164.47% 86.1% at 27.6% 25.03%,
    #12100e 0%,
    #2b4162 100%
  );
  background: rgba(0, 0, 0, 0.2); */

  width: ${({ isPushed }) => (isPushed ? `${SIDEBAR_WIDTH_FULL}px` : 0)};
  height: 100%;
  transition: padding-top 0.2s, width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-right: ${({ isPushed }) =>
    isPushed ? "2px solid rgba(133, 133, 133, 0.1)" : 0};
  z-index: 11;
  overflow: ${({ isPushed }) => (isPushed ? "initial" : "hidden")};
  transform: translate3d(0, 0, 0);
  ${({ isPushed }) => !isPushed && "white-space: nowrap;"};

  ${({ theme }) => theme.mediaQueries.nav} {
    border-right: 2px solid rgba(133, 133, 133, 0.1);
    width: ${({ isPushed }) =>
      `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
  }

  .gamePlay {
    display: flex;
    margin-left: 12px;
    align-items: center;
    margin-top: 10%;
    img {
      margin-right: 8px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .mintNFT {
    display: flex;
    margin: auto;
    margin-left: 12px;
    margin-top: 25%;
    align-items: center;
    img {
      margin-right: 8px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media (max-width: 600px) {
    background: radial-gradient(
      164.47% 86.1% at 27.6% 25.03%,
      #12100e 0%,
      #2b4162 100%
    );
  }
`;

const Img = styled.img`
  width: 150px;
  margin: 30px 20px;
`;

const SocialImg = styled.img`
  width: 35px;
`;

const Links = styled.div`
  margin: auto;
  position: absolute;
  bottom: 14px;
  left: 18px;
  .rights {
    color: white;
    margin-top: 10px;
  }
  p {
    font-weight: 200;
    font-size: 14px;
  }
`;

const SocailLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 88%;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  /* margin-top: 80px; */
`;

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu } = props;
  return (
    <StyledPanel isPushed={isPushed} showMenu={showMenu}>
      <Img
        style={{
          width: "170px",
          margin: "30px 10px",
        }}
        src="/images/vEmpireLogo.png"
        alt="Not found"
      />
      <PanelBody {...props} />
      <a
        className="gamePlay"
        href="https://v-empire.io/games/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/dice.png" alt="not found" />
        <p>Play Games</p>
      </a>
      <a
        className="mintNFT"
        href="https://plebs.v-empire.io/"
        target="_blank"
        rel="noreferrer"
      >
        {/* <p>Pleb Mint Open!</p> */}
      </a>
      <Links>
        <SocailLinks>
          <div>
            <a
              href="https://twitter.com/vEmpireDDAO"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/twitter.png" alt="Not Found" />
            </a>
            <a
              href="https://t.me/vEmpireannouncements"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/telegramm.png" alt="Not Found" />
            </a>
            <a
              href="https://medium.com/@v-empire.digital"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/medium.png" alt="Not Found" />
            </a>
          </div>
          <div>
            <a
              href="https://discord.com/invite/Wk3aF3PNKM"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/discord.png" alt="Not Found" />
            </a>
            <a
              href="https://docs.v-empire.io/vempire-ddao/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/book.png" alt="Not Found" />
            </a>
            <a
              href="https://github.com/v-Empire/vEmpire"
              target="_blank"
              rel="noreferrer"
            >
              <SocialImg src="/images/github.png" alt="Not Found" />
            </a>
          </div>
        </SocailLinks>
        <p className="rights">&copy; 2022 vEmpire DDAO</p>
      </Links>
    </StyledPanel>
  );
};

export default Panel;
