/* eslint-disable import/no-unresolved */

import UAuth from "@uauth/js";
import { setShowUserName } from "state/details";

export const uauth = new UAuth({
  clientID: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scope: "openid wallet",
});

export const getAccountDetails = () => {
  const keys = Object.keys(localStorage);
  const arr = keys.filter((value) => value.includes("authorization"));
  const localStr = JSON.parse(localStorage.getItem(arr[0]));
  const wallet = localStr?.value?.idToken?.wallet_address;
  if (wallet) {
    localStorage.setItem("account", wallet);
  }
};

export const getkeyDetails = () => {
  const keys = Object.keys(localStorage);
  const arr = keys.filter((value) => value.includes("authorization"));
  return arr[0]; // returning the key
};

export const handleLoginButtonClick: React.MouseEventHandler<
  HTMLButtonElement
> = () => {
  uauth.login().catch((error) => {
    console.error("login error:", error);
  });
};

export const handleLogoutButtonClick = (e, dispatch, reset) => {
  uauth
    .logout()
    .then(() => {
      dispatch(setShowUserName(false));
      reset();
      localStorage.clear();
    })
    .catch((error) => {
      console.error("profile error:", error);
      dispatch(setShowUserName(false));
      reset();
      localStorage.clear();
    });
};
