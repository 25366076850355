/* eslint-disable consistent-return */
import WalletConnectProvider from "@walletconnect/web3-provider";
import { bnbNetworkId } from "config";
import Web3 from "web3";

declare const window: any;

export const createWalletProvider = async (walletType: string) => {
  try {
    let options: any = {};
    let provider: any = {};
    options = {
      infuraId: "9aa3d95b3bc440fa88ea12eaa4456161",
      rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        56: "https://bsc-dataseed.binance.org/",
      },
    };
    if (
      (!!walletType && walletType?.toLocaleLowerCase() === "walletconnect") ||
      walletType?.toLocaleLowerCase() === "trust wallet"
    ) {
      provider = new WalletConnectProvider(options);
      await provider.enable();
    } else if (walletType === "Metamask" || walletType === "web3") {
      provider = window?.ethereum;
    } else if (walletType === "OKX Wallet") {
      provider = window?.okxwallet;
    }
    return provider || null;
  } catch (error) {
    console.error(error);
  }
};

export const createWebInstance = async () => {
  try {
    const walletType = localStorage.getItem("wallet");
    const provider: any = await createWalletProvider(walletType);
    return new Web3(provider);
  } catch (error) {
    console.error(error);
  }
};

export default createWalletProvider;
