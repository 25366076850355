import React, { useEffect, Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ResetCSS, ToastContainer } from "@pancakeswap-libs/uikit";
import BigNumber from "bignumber.js";
import { setDetailedData } from "state/details";
import Vote from "views/Vote";
import { useFetchPublicData, useIsWebInstance } from "state/hooks";
import { getAllDetails, getVempTokenPrice } from "utils/farmHarvest";
import {
  accountChanged,
  chainChanged,
  getChainId,
  onDisconnect,
} from "utils/VoteHarvest";
import Plebs from "views/Plebs";
import Soldier from "views/Soldier";
import { createWebInstance } from "utils/wallectConnectUtility";
import { useDispatch } from "react-redux";
import { bnbNetworkId } from "config";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import PageLoader from "./components/PageLoader";
import NftGlobalNotification from "./views/Nft/components/NftGlobalNotification";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import("./views/Home"));
const Callback = lazy(() => import("./Callback"));
const Farms = lazy(() => import("./views/Farms"));
const MakeProposal = lazy(() => import("./views/Vote/MakeProposal"));
const ProposalOverview = lazy(() => import("./views/Vote/ProposalOverview"));
const NotFound = lazy(() => import("./views/NotFound"));
// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [webInstance, setWebInstance] = useState();
  const isWebInstance = useIsWebInstance();
  const [toasts, setToasts] = useState([]);

  const getWebInstance = async () => {
    const webIntance: any = await createWebInstance();
    setWebInstance(webIntance);
  };

  const handleClick = (description = "") => {
    const now = Date.now();
    const randomToast = {
      id: `id-${now}`,
      description,
    };
    setToasts((prevToasts) => [randomToast, ...prevToasts]);
  };
  const handleRemove = (id: string) => {
    setToasts((prevToasts) =>
      prevToasts.filter((prevToast) => prevToast.id !== id)
    );
  };

  const callFunction = async () => {
    await getVempTokenPrice();
    const detail = await getAllDetails();
    dispatch(setDetailedData(detail));
  };

  useEffect(() => {
    callFunction();
    getChainId();
    accountChanged();
    chainChanged();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useFetchPublicData(webInstance);

  useEffect(() => {
    getWebInstance();
    window?.addEventListener("storage", getWebInstance);
  }, [isWebInstance]);

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home webInstance={webInstance} />
            </Route>

            <Route path="/worlds">
              <Farms webInstance={webInstance} handleClick={handleClick} />
            </Route>
            <Route path="/worldsV2">
              <Farms
                tokenMode
                webInstance={webInstance}
                handleClick={handleClick}
              />
            </Route>
            <Route exact path="/vote">
              <Vote webInstance={webInstance} handleClick={handleClick} />
            </Route>
            <Route exact path="/plebs">
              <Plebs webInstance={webInstance} handleClick={handleClick} />
            </Route>
            <Route exact path="/soldiers">
              <Soldier webInstance={webInstance} handleClick={handleClick} />
            </Route>
            <Route exact path="/verify">
              <Callback />
            </Route>
            {localStorage.getItem("chainId") !== bnbNetworkId ? (
              <Route path="/makeProposal">
                <MakeProposal />
              </Route>
            ) : (
              ""
            )}
            <Route path="/history">
              <ProposalOverview />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
      <ToastContainer toasts={toasts} onRemove={handleRemove} />
    </Router>
  );
};

export default React.memo(App);
