import React from "react";
import styled from "styled-components";
import {
  Flex,
  Button,
  useModal,
  Text,
} from "@pancakeswap-libs/uikit";
import { useAPRDetails } from "state/hooks";

const Wrapper = styled(Flex)`
  background-color: black !important;
  svg {
    margin-right: 0.25rem;
  }
`;
const IBtn = styled(Button)`
  height: auto !important;
  padding: 0px !important;
  font-size: 15px !important;
  box-shadow: none;
`;

interface PlebHeaderProps {
  plebStakedArray: any;
  plebArray: any;
}
const CardHeader: React.FC<PlebHeaderProps> = ({
  plebStakedArray,
  plebArray,
}) => {
  const aprDetails = useAPRDetails();

  const [handleInfo] = useModal(
    <div className="warningBorder">
      <div className="info-warning">
        <h1 className="infoHeading">Information</h1>
        <p
          className="infoParagraph"
          style={{ textTransform: "uppercase", fontSize: "17px" }}
        >
          <div>
            Stake your Plebeian Council NFT to receive a share of the game fees
            from vEmpire: The Beginning.
          </div>
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <Wrapper alignSelf="center" mb="18px">
        <h1 style={{ fontWeight: 600, fontSize: "20px", margin: "auto" }}>
          The Plebeian Council
        </h1>

        <div style={{ position: "absolute", right: "12px", top: "20px" }}>
          <IBtn onClick={handleInfo}>
            <span>&#9432;</span>
          </IBtn>
        </div>
      </Wrapper>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom="6px"
      >
        <Text fontSize="18px" style={{ fontWeight: 600 }}>
          APR:
        </Text>
        <Text
          fontSize="18px"
          style={{ display: "flex", alignItems: "center", fontWeight: 600 }}
        >
          {aprDetails?.NFT_STAKING && aprDetails?.NFT_STAKING?.APY
            ? `${aprDetails?.NFT_STAKING?.APY.toFixed(2)}%`
            : "2222%"}
        </Text>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom="6px"
      >
        <Text fontSize="18px" style={{ fontWeight: 600 }}>
          NFT’s:
        </Text>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            width: "60%",
            height: "20px",
            overflowX: "scroll",
            overflowY: "hidden",
            margin: "4px, 4px",
            padding: "4px",
            whiteSpace: "nowrap",
          }}
        >
          {plebArray && plebArray?.join(", ")}
        </div>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom="6px"
      >
        <Text fontSize="18px" style={{ fontWeight: 600 }}>
          Staked:
        </Text>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            width: "60%",
            height: "20px",
            overflowX: "scroll",
            overflowY: "hidden",
            margin: "4px, 4px",
            padding: "4px",
            whiteSpace: "nowrap",
          }}
        >
          {plebStakedArray && plebStakedArray?.join(", ")}
        </div>
      </Flex>
    </div>
  );
};

export default CardHeader;
